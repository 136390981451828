import React, {useRef, useEffect} from 'react';
import {Box} from '../Layout/Box';
import {logging} from 'spekit-datalayer';

export interface IPDFViewer {
  url: string;
  size?: 'small' | 'large' | 'default';
  handleWebViewerFocus?: () => void;
  handleWebViewerLoad?: () => void;
  isExternalView?: boolean;
}

const sizes = {
  large: {
    h: '900px',
  },
  small: {
    h: '400px',
  },
  default: {
    h: '100%',
  },
};
const documentViewerEvents = [
  'keyDown',
  'keyUp',
  'click',
  'mouseEnter',
  'mouseRightUp',
  'mouseRightDown',
  'pageNumberUpdated',
];
export const PDFViewer = ({
  url,
  size = 'default',
  handleWebViewerFocus,
  handleWebViewerLoad,
  isExternalView,
}: IPDFViewer) => {
  const viewer = useRef(null);
  useEffect(() => {
    const initializeWebViewer = async () => {
      try {
        // Dynamically import WebViewer
        // Prevents WebViewer from being bundled and executed in content scripts - where custom elements are not supported
        const {default: WebViewer} = await import(
          /* webpackChunkName: "pdftron-webviewer" */ '@pdftron/webviewer'
        );

        WebViewer.Iframe(
          {
            path: '/',
            licenseKey: process.env.REACT_APP_PDFJS_LICENSE_KEY,
            ui: 'legacy',
            initialDoc: url,
            isReadOnly: true,
            disableObjectURLBlobs: true,
            disabledElements: [
              'downloadButton',
              'saveAsButton',
              'ribbons', // Annotations ribbon
              'toolsHeader', // Extra Annotation tools
              'annotationPopup', // Annotation popup
              'textPopup', // Text selection popup
              'toggleNotesButton', // Comments menu / section
              'contextMenuPopup', // Context menu popup
              'menuButton', // Menu Button
              'menuOverlay', // Menu Overlay
              'thumbnailControl', // Panel Controls
              'pageManipulationOverlayButton', // Page manipulation overlay button
              'pageManipulationOverlay', // Page manipulation overlay
            ],
          },
          // @ts-ignore
          viewer.current
        ).then((instance: any) => {
          const {documentViewer} = instance.Core;
          const fitMode = instance.UI.FitMode;
          instance.UI.setFitMode(fitMode.FitPage);

          // Set custom font URL if it's provided
          // This is used to load fonts from a custom URL
          // ref: https://docs.apryse.com/web/faq/self-serve-substitute-fonts
          if (process.env.REACT_APP_PDFJS_CUSTOM_FONT_URL) {
            instance.Core.setCustomFontURL(process.env.REACT_APP_PDFJS_CUSTOM_FONT_URL);
          }

          documentViewer.addEventListener('documentLoaded', () => {
            if (isExternalView) {
              if (handleWebViewerLoad) handleWebViewerLoad();
              const {documentViewer} = instance.Core;
              documentViewerEvents.forEach((event) => {
                documentViewer.addEventListener(event, handleWebViewerFocus);
              });
            }
          });
        });
      } catch (e) {
        logging.capture(e);
      }
    };

    initializeWebViewer();
  }, [url]);

  return (
    <Box
      ref={viewer}
      {...sizes[size]}
      w='100%'
      flexShrink={0}
      data-testid='pdf-viewer'
      id='pdf-viewer'
    />
  );
};
