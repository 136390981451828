import {AxiosInstance, InternalAxiosRequestConfig, isAxiosError} from 'axios';
import {AxiosCacheInstance} from 'axios-cache-interceptor';
import {ExtensionTokenStorage, WebappTokenStorage, TToken} from './storageClasses';
import {jwtDecode} from 'jwt-decode';
import {add} from '../../datetime';

interface IAuthInterceptorConfig {
  /**
   * Function to refresh the access token
   */
  refreshToken: () => Promise<string>;

  /**
   * Storage to store and retrieve the token
   */
  storage: ExtensionTokenStorage | WebappTokenStorage;

  /**
   * Threshold in seconds before the token expires. defaults to `10` seconds
   */
  timeToExpire?: number;
}

interface IJWTPayload {
  exp: number;
  jti: string;
  iat: number;
  token_type: 'access' | 'refresh';
  user_id: string;
  username: string;
  token_key: string;
  kind: 'app' | 'ext';
}

let expiryThreshold = 10;
let storage: ExtensionTokenStorage | WebappTokenStorage;
let currentRefreshPromise: Promise<TToken> | null = null;

const isTokenExpired = (token: string) => {
  const decoded = jwtDecode<IJWTPayload>(token);
  const exp = new Date(decoded.exp * 1000);
  const now = add(new Date(), {seconds: expiryThreshold});
  return now > exp;
};

const applyStorage = (s: ExtensionTokenStorage | WebappTokenStorage) => {
  if (s) storage = s;
};

const refreshTokenIfNeeded = async (refreshFn: () => Promise<string>) => {
  let token = await storage.getToken();

  if (!token || isTokenExpired(token)) {
    try {
      token = await refreshFn();
      storage.setToken(token);
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          storage.removeToken();
        }
      }
      throw error;
    }
  }

  return token;
};

export const interceptor = ({
  refreshToken,
  storage,
  timeToExpire = 10,
}: IAuthInterceptorConfig) => {
  expiryThreshold = timeToExpire;
  applyStorage(storage);

  return async (config: InternalAxiosRequestConfig) => {
    let token: string | null = null;

    // if its a public route, skip.
    // value set from core/api.ts
    // @ts-expect-error
    if (config.isPublic) return config;

    if (currentRefreshPromise) token = await currentRefreshPromise;

    if (!token) {
      try {
        currentRefreshPromise = refreshTokenIfNeeded(refreshToken);
        token = await currentRefreshPromise;
        currentRefreshPromise = null;
      } catch (error) {
        currentRefreshPromise = null;
        throw error;
      }
    }

    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }

    return config;
  };
};

export const withAuth = (
  instance: AxiosInstance | AxiosCacheInstance,
  config: IAuthInterceptorConfig
) => {
  if (!instance || !instance.interceptors)
    throw new Error(`Invalid axios instance: ${instance}`);

  instance.interceptors.request.use(interceptor(config), Promise.reject);
};
