import React from 'react';
import {Checkbox as ChakraCheckbox, CheckboxProps, forwardRef} from '@chakra-ui/react';
import {StyleConfig} from '../types';
import {Flex} from '../Layout';
import {Text, TText} from '../Typography/Text';
import {colors} from '../theme/Foundations/color/colors';

export interface ICheckboxProps extends CheckboxProps {
  helperText?: string | JSX.Element;
  textProps?: TText;
  helperTextProps?: TText;
}

export const CheckboxConfig: StyleConfig = {
  parts: ['control', 'icon', 'container'],
  baseStyle: {
    control: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      top: '0',
      left: '1px',
    },
    icon: {
      fontSize: 'tiny',
    },
  },
  variants: {
    default: {
      control: {
        borderColor: 'primary.500',
        _hover: {
          backgroundColor: 'primary.25',
        },
        _focus: {
          boxShadow: `0px 0px 0px 4px ${colors.primary['100']}`,
        },
        _disabled: {
          backgroundColor: 'neutral.50',
          borderColor: 'neutral.500',
          color: 'neutral.500',
        },
        _checked: {
          borderColor: 'primary.500',
          color: 'neutral.0',
          background: 'primary.500',
          _hover: {
            borderColor: 'primary.500',
            backgroundColor: 'primary.700',
            color: 'neutral.0',
          },
          _disabled: {
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
          },
        },
        _indeterminate: {
          borderColor: 'primary.500',
          color: 'neutral.0',
          background: 'primary.500',
          _hover: {
            borderColor: 'primary.500',
            backgroundColor: 'primary.700',
            color: 'neutral.0',
          },
          _disabled: {
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
          },
        },
      },
      container: {
        fontSize: 'small',
        fontWeight: 'normal',
        color: 'neutral.800',
        _disabled: {
          color: 'neutral.500',
        },
      },
    },
    light: {
      control: {
        borderColor: 'primary.500',
        backgroundColor: 'neutral.0',
        _hover: {
          backgroundColor: 'primary.25',
        },
        _focus: {
          boxShadow: `0px 0px 0px 4px ${colors.primary['100']}`,
        },
        _disabled: {
          backgroundColor: 'neutral.50',
          borderColor: 'neutral.500',
          color: 'neutral.500',
        },
        _checked: {
          borderColor: 'primary.500',
          color: 'primary.500',
          background: 'neutral.0',
          _hover: {
            borderColor: 'primary.500',
            backgroundColor: 'neutral.0',
            color: 'primary.500',
          },
          _disabled: {
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
          },
        },
      },
      container: {
        fontSize: 'small',
        fontWeight: 'normal',
        color: 'neutral.800',
        _disabled: {
          color: 'neutral.500',
        },
      },
    },
  },
  sizes: {
    default: {
      control: {
        borderRadius: '4',
        h: '14px',
        w: '14px',
        borderWidth: '1px',
      },
    },
    sm: {
      control: {
        borderRadius: 4,
        borderWidth: '1px',
      },
      icon: {
        fontSize: 'micro',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'default',
  },
};

export const Checkbox = forwardRef((props: ICheckboxProps, ref) => {
  const {helperText, textProps, helperTextProps, ...chakraProps} = props;
  return (
    <ChakraCheckbox alignItems={'flex-start'} {...chakraProps} ref={ref}>
      <Flex direction='column' marginTop={'-2px'}>
        <Text fontSize='small' {...textProps}>
          {chakraProps.children}
        </Text>
        {helperText && (
          <Text variant='body2' fontSize='tiny' fontWeight='normal' {...helperTextProps}>
            {helperText}
          </Text>
        )}
      </Flex>
    </ChakraCheckbox>
  );
});
