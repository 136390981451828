import React, {useEffect, useRef, useState} from 'react';
import {file} from 'spekit-datalayer';
import {
  DSPopover as Popover,
  PopoverContent,
  Portal,
  PopoverTrigger,
  notifications,
  DSButton as Button,
  Flex,
  Icon,
  Image,
} from 'spekit-ui';
import {PulseLoader} from 'react-spinners';
import {iconMapper} from '../utils/iconMapper';
import {generalBiz_bookmark} from '../TopicIcons/icons/general';
import TopicIconGrid from '../TopicIcons/components/TopicIconGrid';
import {RiMoreFill, RiUploadLine} from 'react-icons/ri';
const {notify} = notifications;

interface ImageObject {
  id?: string;
  view: string;
  download: string;
}

interface TUProps {
  fontawesome: string | null;
  getIcon: (icon: any) => void;
  image: string | ImageObject | null;
}

const isImageObject = (test: string | ImageObject | null): test is ImageObject => {
  if (typeof test === 'string') {
    return false;
  } else if (!test) {
    return false;
  } else {
    return true;
  }
};
const TopicUpload = (props: TUProps) => {
  const {fontawesome, getIcon, image} = props;
  const prevPropsRef = useRef<string | null>();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{
    fontawesome: string | null;
    fontawesomeString: string | null;
    image: string | null;
    faIcon: boolean;
    busy: boolean;
  }>({
    fontawesome: null,
    fontawesomeString: fontawesome !== null ? fontawesome : '',
    image: generalBiz_bookmark,
    faIcon: true,
    busy: false,
  });

  useEffect(() => {
    if (fontawesome !== prevPropsRef.current) {
      setState({...state, fontawesomeString: fontawesome || ''});
    }
    prevPropsRef.current = fontawesome;
  }, [fontawesome, state]);

  const getResponseErrorMsg = (errorCode: string) => {
    switch (errorCode) {
      case '403':
        return 'You’ve reached the media hosting limit for your Spekit account. Please contact us to upgrade.';
      case '413':
        return 'The file is too big. Please make sure file size is less than 20 MB.';
      default:
        return 'There was an error uploading the file. Please try again.';
    }
  };

  const previewFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files[0]) {
        setState({...state, busy: true});
        const response = await file.upload(e.target.files[0]);
        setState({
          ...state,
          image: response.url.view,
          fontawesome: null,
          fontawesomeString: null,
          busy: false,
        });
        getIcon({
          image: response.id,
          fontawesome: null,
        });
      }
    } catch (err) {
      notify({
        error: true,
        text: getResponseErrorMsg(err.message),
      });
    }
  };

  const onIconClick = (icon: string, iconString: string, faIcon: boolean) => {
    setState({
      ...state,
      fontawesome: icon,
      image: null,
      fontawesomeString: iconString,
      faIcon: faIcon,
      busy: false,
    });
    getIcon({
      image: null,
      fontawesome: iconString,
      faIcon,
    });
  };

  return (
    <Flex direction='row' wrap='nowrap' gap={4}>
      {state.busy ? (
        <Flex data-testid='loader' alignItems='center' p={4}>
          <PulseLoader loading={true} size={10} color='#653FE6' />
        </Flex>
      ) : state.fontawesome === null && fontawesome === null ? (
        <Image
          data-testid='uploaded-image'
          alt='Preview...'
          src={image !== null && isImageObject(image) ? image.view : state.image || ''}
          boxSize={'64px'}
          objectFit='cover'
        />
      ) : (
        <Flex justify='center' align='center'>
          {!!fontawesome &&
          state.fontawesomeString &&
          state.fontawesomeString.length > 0 &&
          !state.fontawesomeString.startsWith('fa') ? (
            <Image
              data-testid={`parent-${fontawesome}`}
              alt='icon'
              src={iconMapper(fontawesome)}
              boxSize={'64px'}
              objectFit='cover'
            />
          ) : (
            <Icon
              data-testid={`parent-${state.fontawesomeString}`}
              as={iconMapper(state.fontawesomeString)}
              w='64px'
              h='64px'
              color='primary.500'
            />
          )}
        </Flex>
      )}
      <Flex
        direction='column'
        wrap='nowrap'
        justifyContent='space-evenly'
        alignItems='flex-start'
      >
        <Popover variant='icon'>
          <>
            <PopoverTrigger>
              <Button
                data-testid='choose-icon-btn'
                size='small'
                variant='ghost'
                colorScheme='primary'
                leftIcon={<Icon as={RiMoreFill} />}
              >
                Icon library
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                width='auto'
                height='382px'
                position='fixed'
                top='0'
                overflow='auto'
                boxShadow='lg'
                border='1px'
                borderColor='neutral.200'
                borderStyle='solid'
              >
                <TopicIconGrid onClick={onIconClick} />
              </PopoverContent>
            </Portal>
          </>
        </Popover>
        <Button
          data-testid='upload-icon-btn'
          size='small'
          variant='ghost'
          colorScheme='primary'
          leftIcon={<Icon as={RiUploadLine} />}
          onClick={() => inputFileRef.current && inputFileRef.current.click()}
        >
          <input
            type='file'
            data-testid='file-input'
            onChange={previewFile}
            ref={inputFileRef}
            hidden={true}
          />
          Upload custom icon
        </Button>
      </Flex>
    </Flex>
  );
};

export default TopicUpload;
