import React, {RefObject, useEffect} from 'react';
import {CreateAsset, EditAsset} from './components';
import {IAsset, IExtendedNotificationState, IOptionType, ISession} from 'spekit-types';
import {querySelectorDeep} from 'query-selector-shadow-dom';
import {TElementWithVisibility, isComponentVisible} from '../utils/liveAssertUtils';

export interface ICloseProps {
  isSuccessful?: boolean;
  selectedTopics?: IOptionType[];
  files?: number;
  isLF?: boolean;
  fromEdit?: boolean;
  assetId?: string;
  notification?: IExtendedNotificationState;
  fileDetails?: Partial<IOptionType>;
}

interface Props {
  isOpen: boolean;
  onClose: (closeArgs?: ICloseProps) => void;
  containerRef?: RefObject<HTMLElement>;
  editableAsset?: IAsset;
  editableFile?: File;
  track?: any;
  selectedFiles?: File[] | null;
  selectedTopic?: IOptionType | null;
  host?: string | null;
  session?: ISession;
}

export const AssetModal = (props: Props) => {
  const {editableAsset, editableFile} = props;
  const {isOpen} = props;
  useEffect(() => {
    if (!isOpen || editableAsset) return;
    setTimeout(() => {
      const el = querySelectorDeep(
        '#chakra-modal-create-asset-modal'
      ) as TElementWithVisibility;
      isComponentVisible({
        element: el,
        message: 'File upload modal is not visible',
        tag: 'File-upload-modal',
      });
    }, 1000);
  }, [isOpen]);

  return editableAsset ? (
    <EditAsset editableAsset={editableAsset} editableFile={editableFile} {...props} />
  ) : (
    <CreateAsset {...props} />
  );
};

export default AssetModal;
