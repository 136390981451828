import React from 'react';
import {FormProvider, useForm, Controller} from 'react-hook-form';
import {adminToolsAPI, EXISTING_RESOURCE, logging} from 'spekit-datalayer';
import {TCustomField, ICustomFieldsFormState} from 'spekit-types';
import {
  DSButton as Button,
  ControlledSelect,
  EncapsulatedInput,
  Flex,
  VStack,
  useToast,
} from 'spekit-ui';
import {track} from '../../../../utils/analytics';
import {FIELD_TYPES, getContentTypes, getFormValues, resolver} from './form';
import Options from './Options';

export interface ICustomFieldsForm {
  field?: TCustomField;
  handleModalClose: () => void;
  onSave: () => void;
}

export const CustomFieldsForm = ({
  field,
  onSave,
  handleModalClose,
}: ICustomFieldsForm) => {
  const toast = useToast();
  const isInEditMode = !!field;

  const SUCCESS_MESSAGE = `Custom field ${isInEditMode ? 'updated' : 'created'}!`;
  const FAILURE_MESSAGE = `Custom field could not be ${
    isInEditMode ? 'updated' : 'created'
  }!`;
  const TRACKING_MESSAGE = `Wiki: Custom Column ${isInEditMode ? 'Updated' : 'Created'}`;

  const methods = useForm<ICustomFieldsFormState>({
    defaultValues: getFormValues(field, true),
    mode: 'onChange',
    resolver,
  });
  const {
    watch,
    control,
    setError,
    handleSubmit,
    formState: {isSubmitting},
  } = methods;
  const fieldType = watch('data_type');

  const createOrUpdateHandler = async (state: ICustomFieldsFormState) => {
    try {
      const response = await (isInEditMode
        ? adminToolsAPI.updateCustomField(field.id, state)
        : adminToolsAPI.createCustomField(state));

      if (response.success) {
        onSave();
        track(TRACKING_MESSAGE, {screen_name: 'Custom Columns'});
        toast({description: SUCCESS_MESSAGE, variant: 'success'});
      } else {
        throw new Error(response.message);
      }
    } catch (e) {
      if (e.message === EXISTING_RESOURCE) {
        setError('name', {
          type: 'manual',
          message: 'Custom field with this name already exists',
        });
      } else {
        logging.capture(e);
        toast({description: FAILURE_MESSAGE, variant: 'error'});
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <VStack spacing={10}>
        <Controller
          name='name'
          control={control}
          render={({field, fieldState}) => (
            <EncapsulatedInput
              {...field}
              isRequired
              label='Name'
              testId='field-name'
              placeholder='Enter name'
              isInvalid={!!fieldState.error}
              errorMessage={fieldState.error?.message}
            />
          )}
        />

        <ControlledSelect
          isRequired
          label='Type'
          id='data-type'
          name='data_type'
          testId='data-type'
          options={FIELD_TYPES}
          placeholder='Select type'
          isDisabled={isInEditMode}
        />

        {fieldType?.value === 'multi-option' && <Options />}

        <ControlledSelect
          id='on'
          isMulti
          name='on'
          isRequired
          label='Applies to'
          options={getContentTypes(true)}
          placeholder='Select content types this custom field applies to'
          testId='applies-to'
        />
      </VStack>

      <Flex gap={6} mt={32} justifyContent='flex-end' alignSelf='stretch'>
        <Button
          size='medium'
          variant='ghost'
          colorScheme='white'
          onClick={handleModalClose}
          data-testid='cancel-btn'
        >
          Cancel
        </Button>
        <Button
          size='medium'
          variant='contained'
          colorScheme='primary'
          isLoading={isSubmitting}
          onClick={handleSubmit(createOrUpdateHandler)}
          data-testid='create-btn'
        >
          {isInEditMode ? 'Save changes' : 'Create'}
        </Button>
      </Flex>
    </FormProvider>
  );
};
