import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import {
  useIntegrationApp,
  Connection,
  IntegrationAppClient,
} from '@integration-app/react';
import {useConnections} from './useConnections';
import {IDrive} from 'spekit-types';
import {
  INTEGRATION_KEY_CONFLUENCE,
  INTEGRATION_KEY_GOOGLE,
  INTEGRATION_KEY_SHAREPOINT,
  MY_DRIVE,
} from '../constants';

interface IMSSite {
  createdDateTime: string;
  id: string;
  lastModifiedDateTime: string;
  name: string;
  webUrl: string;
  displayName: string;
  root: {};
  siteCollection: {
    hostname: string;
  };
}

const filterSites = (data: IMSSite[]) =>
  data.filter(
    (site: IMSSite) =>
      !(site.webUrl.includes('portals') || site.webUrl.includes('appcatalog'))
  );

class RequestThrottler {
  private requestCount: number = 0;
  private lastRequestTime: number = Date.now();
  private readonly requestsPerSecond: number = 100;
  private readonly intervalMs: number = 1000;

  async acquireToken(): Promise<void> {
    const currentTime = Date.now();
    if (currentTime - this.lastRequestTime >= this.intervalMs) {
      this.requestCount = 0;
      this.lastRequestTime = currentTime;
    }

    if (this.requestCount >= this.requestsPerSecond) {
      const delay = this.intervalMs - (currentTime - this.lastRequestTime);
      if (delay > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
      this.requestCount = 0;
      this.lastRequestTime = Date.now();
    }

    this.requestCount++;
  }
}

const throttler = new RequestThrottler();

const useSites = (hasContentIntegrationSharepointFlag?: boolean) => {
  const integrationApp = useIntegrationApp();

  const sites = useQuery({
    queryKey: ['sites'],
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      const response = await integrationApp
        .connection(INTEGRATION_KEY_SHAREPOINT)
        .action('list-sites')
        .run();
      return response.output.records.map((result: any) => result.fields) as IMSSite[];
    },
    enabled: hasContentIntegrationSharepointFlag,
    select: filterSites,
    refetchOnWindowFocus: false,
  });

  return {sites: sites.data || [], isLoading: sites.isLoading, isError: sites.isError};
};

const useSharepointDrives = (
  connection: Connection,
  hasContentIntegrationSharepointFlag?: boolean
) => {
  const integrationApp = useIntegrationApp();

  const {sites, isLoading, isError} = useSites(hasContentIntegrationSharepointFlag);

  const drives = useQueries({
    queries: sites.map((site, index) => {
      return {
        retry: false,
        staleTime: 5 * 60 * 1000,
        queryKey: ['drives', INTEGRATION_KEY_SHAREPOINT, site.id],
        queryFn: async () => {
          const batchIndex = Math.floor(index / 100);
          const delayMs = batchIndex * 1000;
          await new Promise((resolve) => setTimeout(resolve, delayMs));

          await throttler.acquireToken();

          const response = await integrationApp
            .connection(INTEGRATION_KEY_SHAREPOINT)
            .action('list-drives')
            .run({siteId: site.id});
          return response.output.records.map((result: any) => ({
            ...result.fields,
            name: site.displayName + ' - ' + result.fields.name,
          })) as IDrive[];
        },
        enabled: Boolean(connection && !connection.disconnected && !isLoading && site.id),
        refetchOnWindowFocus: false,
      };
    }),
  });

  return {
    data: drives.flatMap((drive) => drive.data || []),
    isLoading: drives.some((drive) => drive.isLoading) || isLoading,
    isError: drives.some((drive) => drive.isError) || isError,
  };
};

const useGoogleDrives = (connection: Connection) => {
  const integrationApp = useIntegrationApp();

  const drives = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['drives', INTEGRATION_KEY_GOOGLE],
    queryFn: async () => {
      const response = await integrationApp
        .connection(INTEGRATION_KEY_GOOGLE)
        .action('list-drives')
        .run();
      const results = response.output.records.map(
        (result: any) => result.fields
      ) as IDrive[];
      return [MY_DRIVE, ...results];
    },
    enabled: Boolean(connection && !connection.disconnected),
    refetchOnWindowFocus: false,
  });

  return drives;
};

const useConfluence = (connection: Connection) => {
  // todo: use this hook to extend confluence spaces, blogs, etc.
  const drives = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['drives', INTEGRATION_KEY_CONFLUENCE],
    queryFn: async () => {
      return [
        {
          id: '001',
          name: 'Pages',
        },
      ];
    },
    enabled: Boolean(connection && !connection.disconnected),
    refetchOnWindowFocus: false,
  });

  return drives;
};

export const useDrives = (
  hasContentIntegrationSharepointFlag?: boolean,
  hasContentIntegrationConfluenceFlag?: boolean
) => {
  const {connections, stores} = useConnections(
    hasContentIntegrationSharepointFlag,
    hasContentIntegrationConfluenceFlag
  );

  const googleDrives = useGoogleDrives(connections[INTEGRATION_KEY_GOOGLE]);

  const sharepointDrives = useSharepointDrives(
    connections[INTEGRATION_KEY_SHAREPOINT],
    hasContentIntegrationSharepointFlag
  );

  const drives = [
    {
      drives: googleDrives,
      store: stores[0],
      connection: connections[INTEGRATION_KEY_GOOGLE],
    },
  ];

  if (hasContentIntegrationSharepointFlag) {
    drives.push({
      drives: sharepointDrives as UseQueryResult<IDrive[], any>,
      store: stores[1],
      connection: connections[INTEGRATION_KEY_SHAREPOINT],
    });
  }

  const confluenceDrives = useConfluence(connections[INTEGRATION_KEY_CONFLUENCE]);

  if (hasContentIntegrationConfluenceFlag) {
    // todo: remove this once confluence is implemented
    drives.push({
      drives: confluenceDrives,
      store: stores[2],
      connection: connections[INTEGRATION_KEY_CONFLUENCE],
    });
  }

  return drives;
};
