import {IntegrationAppClient} from '@integration-app/react';
import {IActionOutput, IFileOrFolder, IFilePickerParams} from 'spekit-types';

// this will be used to extend the hooks for confluence search functionality to include more filters later on.
// currently, it only supports search by title.

export const performConfluenceSearch = async (
  iApp: IntegrationAppClient,
  params: IFilePickerParams
): Promise<IFileOrFolder[]> => {
  const searchTerm = params.searchTerm?.trim();
  if (!searchTerm) {
    return [];
  }

  // Remove special characters that could break the cpql query.
  const sanitizedTerm = searchTerm.replace(/[*"\\]/g, '');

  try {
    const response = await iApp
      .connection(params.store)
      .action('search-documents')
      .run({
        query: `type = "page" and title ~ "*${sanitizedTerm}*" order by lastModified desc`,
      });

    const output = response.output as IActionOutput<IFileOrFolder>;

    if (!output?.records?.length) {
      return [];
    }

    return output.records.map((file) => ({
      ...file,
      id: file.fields.id,
      name: file.fields.title,
      updatedTime: file.fields.updatedTime,
    })) as IFileOrFolder[];
  } catch (error) {
    console.error('Error searching Confluence:', error);
    return [];
  }
};
