import React from 'react';
import {RiHardDrive2Line} from 'react-icons/ri';
import {IDrive} from 'spekit-types';
import {Flex, Icon, Text, DSTooltip as Tooltip, ConfluenceIcon} from 'spekit-ui';
import {IContentStore} from '../../constants';
import {DriveIcon, SharepointIcon} from '../Icons';
interface IProps {
  drive: IDrive;
  onClick: (drive: IDrive) => void;
  isSelected?: boolean;
  store?: IContentStore;
}

export const DriveCard = ({drive, isSelected, onClick, store}: IProps) => {
  const icons = {
    gdrive: DriveIcon,
    'microsoft-sharepoint': SharepointIcon,
    confluence: ConfluenceIcon,
  };

  const handleClick = () => {
    onClick(drive);
  };

  let border = {
    borderWidth: 1,
    borderColor: 'neutral.200',
    borderStyle: 'solid',
  };
  if (isSelected) {
    border = {
      borderWidth: 2,
      borderColor: 'primary.500',
      borderStyle: 'solid',
    };
  }

  return (
    <Flex
      my='1px'
      gap={10}
      boxSizing='border-box'
      borderRadius={8}
      boxShadow='md'
      cursor='pointer'
      data-testid={`${drive.name}-drive`}
      onClick={handleClick}
      p={26}
      {...border}
      _hover={{
        transition: 'all 0.2s',
        shadow: 'md',
        borderWidth: 1,
        borderColor: 'primary.500',
        borderStyle: 'solid',
      }}
    >
      <Icon fontSize={24} as={icons[store?.connectionKey as keyof typeof icons]} />
      <Tooltip label={drive.name}>
        <Text fontWeight='600' variant='body1' noOfLines={1}>
          {drive.name}
        </Text>
      </Tooltip>
    </Flex>
  );
};
