import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {RiAddCircleLine, RiDeleteBin6Line} from 'react-icons/ri';
import {Flex, EncapsulatedInput, DSButton as Button, Icon, Box} from 'spekit-ui';

interface IPathsProps {
  urlIndex: number;
  isRemovableDomain: boolean;
}

export const Paths = (props: IPathsProps) => {
  const {urlIndex, isRemovableDomain} = props;
  const {control} = useFormContext();

  const {
    fields: paths,
    append,
    remove,
  } = useFieldArray({
    name: `domains.${urlIndex}.paths`,
    control,
  });

  const isRemovablePath = paths.length > 1;

  return (
    <>
      {paths.map((path, index) => (
        <Flex key={path.id} ml={24} direction='column' gap={12}>
          <Flex gap={12}>
            <Controller
              name={`domains.${urlIndex}.paths.${index}.value`}
              render={({field: {value, onChange}, fieldState}) => (
                <>
                  <Box width={isRemovableDomain ? '382px' : '100%'}>
                    <EncapsulatedInput
                      value={value}
                      onChange={onChange}
                      placeholder='Enter path'
                      isInvalid={!!fieldState.error}
                      errorMessage={fieldState.error?.message}
                    />
                  </Box>
                  {isRemovablePath && (
                    <Button
                      onClick={() => remove(index)}
                      variant='icon'
                      icon={<Icon as={RiDeleteBin6Line} />}
                      size='medium'
                      colorScheme='white'
                      aria-label='Delete path'
                      tooltipLabel='Delete path'
                      tooltipPlacement='bottom'
                      alignSelf={!!fieldState.error ? 'flex-start' : 'flex-end'}
                      mb={!fieldState.error ? 6 : 0}
                      mt={!!fieldState.error ? 6 : 0}
                    />
                  )}
                </>
              )}
            />
          </Flex>
        </Flex>
      ))}
      <Button
        colorScheme='primary'
        leftIcon={<Icon as={RiAddCircleLine} />}
        size='medium'
        variant='ghost'
        width='fit-content'
        onClick={() => append({value: ''})}
        ml={24}
      >
        Add path
      </Button>
    </>
  );
};
