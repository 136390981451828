import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {ROLES} from 'spekit-datalayer';
import {Flex, Heading, Tabs, TabList, Tab, TabPanels, TabPanel} from 'spekit-ui';
import {adminToolsPermissions} from '../../../../components/SideNavigation/useSettings';
import {SystemPrivacy} from '../../../../components/SystemPrivacy/SystemPrivacy';
import {RootState} from '../../../../reduxStore';
import {hasSomeOf} from '../../../../utils/permissions';
import {BulkUpload} from '../../components/BulkUpload';
import {CustomFields} from '../../components/CustomFields/CustomFields';
import {CustomBrandinTab} from '../CustomBranding/CustomBrandinTab';

export const AdminTools = () => {
  const {role} = useSelector((state: RootState) => state.layout.me);

  if (![ROLES.SUPER_ADMIN, ROLES.ACCOUNT_ADMIN].includes(role)) {
    return <Redirect to='/notAllowed' />;
  }

  const generateTabs = () => {
    const tabs = [
      {name: 'Custom fields', Component: CustomFields, testId: 'field-tab'},
      {name: 'Bulk upload', Component: BulkUpload, testId: 'bulk-upload-tab'},
      {
        name: 'Custom branding',
        Component: CustomBrandinTab,
        testId: 'custom-branding-tab',
      },
    ];

    if (hasSomeOf(adminToolsPermissions)) {
      tabs.push({
        name: 'Share settings',
        Component: SystemPrivacy,
        testId: 'share-tab',
      });
    }

    return tabs;
  };
  const tabs = generateTabs();

  return (
    <Flex direction='column' gap={26}>
      <Heading as='h2' mb={30} fontWeight={'semibold'}>
        Admin tools
      </Heading>
      <Tabs>
        <TabList mb={26}>
          {tabs.map(({name, testId}) => (
            <Tab key={name} data-testid={testId}>
              {name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map(({name, Component}) => (
            <TabPanel key={name}>
              <Component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
