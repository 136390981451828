import React from 'react';
import {Dispatch, State} from '../types';
import {FormLabel, FormControl, FormErrorMessage, Input, Box} from 'spekit-ui';
import {RichTextEditor} from '../../RichTextEditor/RichTextEditor';
import {spotlightHeaderEditorConfig} from '../../RichTextEditor/config/editorConfig';

interface Props {
  state: State;
  dispatch: Dispatch;
  maxHeadlineLength: number;
}

const Headline: React.FC<Props> = ({state, dispatch, maxHeadlineLength}) => {
  return (
    <FormControl
      isRequired
      isInvalid={state.headlineError || state.spotlightHeadlineCount >= maxHeadlineLength}
    >
      <FormLabel mt={16}>Headline</FormLabel>

      <Box className='spotlight-editor-compact'>
        <RichTextEditor
          placeholder='Enter headline'
          value={state.headline}
          onChange={(value) => {
            dispatch({type: 'SET_HEADLINE', payload: value});
          }}
          onCharactersCountChange={(value) =>
            dispatch({type: 'SET_HEADLINE_CHARACTERS_COUNT', payload: value})
          }
          setUploadBusy={() => {}}
          unSetUploadBusy={() => {}}
          editingViewReadOnly={state.read_mode}
          config={spotlightHeaderEditorConfig}
        />
      </Box>

      <FormErrorMessage data-testid='headline-error-message'>
        {state.headline.length >= maxHeadlineLength &&
          'Headline must be less than 140 characters'}
        {state.headlineError &&
          !(state.headline.length >= maxHeadlineLength) &&
          'Enter headline'}
      </FormErrorMessage>
    </FormControl>
  );
};

export default Headline;
