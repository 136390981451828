import {Suspense, lazy} from 'react';
import {Switch, Route} from 'react-router-dom';

const ListPage = lazy(() => import('./pages/ListPage'));

function SearchRoutes() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/search'>
          <ListPage />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default SearchRoutes;
