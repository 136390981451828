import React from 'react';

import {forwardRef} from '@chakra-ui/react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  InputGroup,
  InputRightElement,
  TTextareaProps,
} from '../../Form';

import {Tooltip} from '../../Tooltip';
import {Box} from '../../Layout';

import {RiInformationLine} from 'react-icons/ri';
import {Flex} from '@chakra-ui/react';
import {Text} from '../../Typography';
import {ERROR_MESSAGE_TEST_ID} from 'spekit-datalayer';

export interface EncapsulatedInputProps {
  value: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  isInvalid?: boolean;
  tooltip?: string;
  multiline?: boolean;
  helpText?: string | React.ReactNode;
  onChange: (value: string) => void;
  characterLimit?: number;
  testId?: string;
  isDisabled?: boolean;
  inputControl?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  resizable?: boolean;
  textareaProps?: TTextareaProps;
}

export const EncapsulatedInput = forwardRef(
  (
    {
      value,
      label,
      placeholder,
      isRequired,
      errorMessage,
      tooltip,
      multiline,
      helpText,
      onChange,
      isInvalid,
      characterLimit,
      testId,
      isDisabled,
      inputControl,
      type = 'text',
      resizable,
      textareaProps,
    }: EncapsulatedInputProps,
    ref
  ) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange(e.target.value);
    };
    return (
      <FormControl textAlign='left' isInvalid={isInvalid} isRequired={isRequired}>
        {/* we want an optional tooltip here. We must wrap the label and tooltip so that the required asterisk is not included after the tooltip */}
        {label && (
          <Box display={'flex'} alignItems={'center'}>
            <FormLabel marginInlineEnd={1}>{label}</FormLabel>
            {tooltip && (
              <Tooltip label={tooltip} placement='top'>
                <Box
                  as={'span'}
                  display={'flex'}
                  alignItems={'center'}
                  data-testid='encapsulated input tooltip'
                >
                  <RiInformationLine fontSize={'1rem'} />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
        {multiline ? (
          <Textarea
            data-testid={testId}
            onChange={handleChange}
            placeholder={placeholder}
            value={value}
            isDisabled={isDisabled}
            resize={resizable ? 'both' : 'none'}
            {...textareaProps}
          />
        ) : (
          <InputGroup>
            <Input
              data-testid={testId}
              onChange={handleChange}
              value={value}
              type={type}
              placeholder={placeholder}
              isDisabled={isDisabled}
              isInvalid={isInvalid}
            />
            {inputControl && <InputRightElement>{inputControl}</InputRightElement>}
          </InputGroup>
        )}
        <Flex justifyContent='space-between'>
          <span>
            {/* hide help text on error. error visibility handled by isInvalid prop on form control */}
            {helpText && !isInvalid && <FormHelperText>{helpText}</FormHelperText>}
            {isInvalid && errorMessage && (
              <FormErrorMessage data-testid={ERROR_MESSAGE_TEST_ID}>
                {errorMessage}
              </FormErrorMessage>
            )}
          </span>
          {characterLimit !== undefined && (
            <Text
              fontSize='tiny'
              mt={1}
              color={value.length > characterLimit ? 'error.600' : 'neutral.800'}
            >
              {value.length}/{characterLimit}
            </Text>
          )}
        </Flex>
      </FormControl>
    );
  }
);
