import React from 'react';
import {Button as ChakraButton, IconButton, forwardRef} from '@chakra-ui/react';

import {StyleConfig, TSize, TButtonProps} from '../types';
import {FONT_FAMILY} from '../Typography';
import {Tooltip} from '../Tooltip';
import {TColors} from '../theme/Foundations/color';

const getOutlinedStyle = (colors: TColors) => ({
  background: 'neutral.0',
  border: '1px',
  borderColor: 'neutral.400',
  color: 'neutral.800',
  _hover: {
    background: 'primary.25',
    borderColor: 'primary.500',
    color: 'neutral.900',
    _disabled: {
      background: 'neutral.0',
      borderColor: 'neutral.300',
      color: 'neutral.300',
      opacity: 1,
    },
  },
  _active: {
    borderColor: 'primary.500',
    color: 'neutral.800',
    boxShadow: `0px 0px 0px 4px ${colors.primary['100']}`,
    _disabled: {
      background: 'neutral.0',
      borderColor: 'neutral.300',
      color: 'neutral.300',
      boxShadow: 'none',
    },
  },
  _focusVisible: {
    boxShadow: `inset 0 0 0 2px ${colors.primary['500']}`,
  },
  _disabled: {
    background: 'neutral.0',
    borderColor: 'neutral.300',
    color: 'neutral.300',
    opacity: 1,
  },
});

export const ButtonConfig: StyleConfig = {
  baseStyle: () => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'regular',
    fontFamily: FONT_FAMILY,
    lineHeight: 1,
    _focus: {
      boxShadow: 'none',
    },
  }),
  variants: {
    contained: ({colorScheme, theme}) => {
      const {colors} = theme;
      if (colorScheme === 'primary') {
        return {
          borderColor: 'transparent',
          background: 'primary.500',
          color: 'neutral.0',
          fontWeight: 'semibold',
          _hover: {
            background: 'primary.700',
            _disabled: {
              background: 'neutral.200',
              opacity: 1,
            },
          },
          _active: {
            background: 'primary.700',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['0']}`,
          },
          _disabled: {
            background: 'neutral.200',
            opacity: 1,
          },
        };
      }
      if (colorScheme === 'secondary') {
        return {
          borderColor: 'transparent',
          background: 'pink.500',
          color: 'neutral.0',
          fontWeight: 'semibold',
          _hover: {
            background: 'pink.700',
            _disabled: {
              background: 'neutral.200',
              opacity: 1,
            },
          },
          _active: {
            background: 'pink.700',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['0']}`,
          },
          _disabled: {
            background: 'neutral.200',
            opacity: 1,
          },
        };
      }
      if (colorScheme === 'dark') {
        return {
          borderColor: 'transparent',
          background: 'rgba(255,255,255,0.3)',
          color: 'neutral.0',
          _hover: {
            background: 'rgba(255,255,255,0.5)',
            color: 'neutral.0',
            _disabled: {
              background: 'rgba(255,255,255,0.3)',
              color: 'neutral.0',
              opacity: 0.4,
              cursor: 'not-allowed',
              boxShadow: 'none',
            },
          },
          _active: {
            background: 'rgba(255,255,255,0.5)',
            color: 'neutral.0',
            boxShadow: '0px 0px 0px 4px rgba(255 255 255 / 75%)',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
          },
          _disabled: {
            background: 'rgba(255,255,255,0.3)',
            color: 'neutral.0',
            opacity: 0.4,
            cursor: 'not-allowed',
            boxShadow: 'none',
          },
        };
      }
      return {
        borderColor: 'transparent',
        background: 'neutral.0',
        color: 'neutral.800',
        _hover: {
          background: 'neutral.200',
          color: 'neutral.900',
          _disabled: {
            background: 'neutral.0',
            color: 'neutral.300',
            opacity: 1,
          },
        },
        _active: {
          background: 'neutral.200',
          color: 'neutral.900',
        },
        _focusVisible: {
          background: 'neutral.200',
          boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
        },
        _disabled: {
          background: 'neutral.0',
          color: 'neutral.300',
          opacity: 1,
        },
      };
    },
    ghost: ({colorScheme, theme}) => {
      const {colors} = theme;
      if (colorScheme === 'primary') {
        return {
          borderColor: 'transparent',
          background: 'none',
          color: 'primary.500',
          _hover: {
            color: 'primary.700',
            background: 'primary.100',
            _disabled: {
              color: 'neutral.300',
              background: 'none',
              opacity: 1,
            },
          },
          _active: {
            color: 'neutral.0',
            background: 'primary.500',
          },
          _focusVisible: {
            color: 'neutral.0',
            background: 'primary.500',
            boxShadow: `inset 0 0 0 2px ${colors.neutral['0']}`,
          },
          _disabled: {
            color: 'neutral.300',
            background: 'none',
            opacity: 1,
          },
        };
      }
      return {
        borderColor: 'transparent',
        background: 'none',
        color: 'neutral.800',
        _hover: {
          color: 'neutral.900',
          background: 'neutral.100',
          _disabled: {
            color: 'neutral.300',
            background: 'none',
            opacity: 1,
          },
        },
        _active: {
          color: 'neutral.0',
          background: 'neutral.800',
        },
        _focusVisible: {
          background: 'neutral.100',
          boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
        },
        _disabled: {
          color: 'neutral.300',
          background: 'none',
          opacity: 1,
        },
      };
    },
    icon: ({colorScheme, theme}) => {
      const {colors} = theme;
      if (colorScheme === 'primary') {
        return {
          borderColor: 'transparent',
          background: 'primary.500',
          color: 'neutral.0',
          _hover: {
            background: 'primary.700',
            _disabled: {
              background: 'neutral.200',
              opacity: 1,
            },
          },
          _active: {
            background: 'primary.700',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['0']}`,
          },
          _disabled: {
            background: 'neutral.200',
            opacity: 1,
          },
        };
      }
      if (colorScheme === 'secondary') {
        return {
          borderColor: 'transparent',
          background: 'pink.500',
          color: 'neutral.0',
          _hover: {
            background: 'pink.700',
            _disabled: {
              background: 'neutral.200',
              opacity: 1,
            },
          },
          _active: {
            background: 'pink.700',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['0']}`,
          },
          _disabled: {
            background: 'neutral.200',
            opacity: 1,
          },
        };
      }
      if (colorScheme === 'dark') {
        return {
          borderColor: 'transparent',
          background: 'rgba(255,255,255,0.3)',
          color: 'neutral.0',
          _hover: {
            background: 'rgba(255,255,255,0.5)',
            color: 'neutral.0',
            _disabled: {
              background: 'rgba(255,255,255,0.3)',
              color: 'neutral.0',
              opacity: 0.4,
              cursor: 'not-allowed',
              boxShadow: 'none',
            },
          },
          _active: {
            background: 'rgba(255,255,255,0.5)',
            color: 'neutral.0',
            boxShadow: '0px 0px 0px 4px rgba(255 255 255 / 75%)',
          },
          _focusVisible: {
            boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
          },
          _disabled: {
            background: 'rgba(255,255,255,0.3)',
            color: 'neutral.0',
            opacity: 0.4,
            cursor: 'not-allowed',
            boxShadow: 'none',
          },
        };
      }
      if (colorScheme === 'outlined') {
        return getOutlinedStyle(colors);
      }
      return {
        borderColor: 'transparent',
        background: colorScheme === 'white' ? 'neutral.0' : 'transparent',
        color: 'neutral.800',
        _hover: {
          background: 'neutral.200',
          color: 'neutral.900',
          _disabled: {
            background: colorScheme === 'white' ? 'neutral.0' : 'transparent',
            color: 'neutral.300',
            opacity: 1,
          },
        },
        _active: {
          background: colorScheme === 'primary' ? 'primary.500' : 'neutral.800',
          color: 'neutral.0',
        },
        _focusVisible: {
          boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
        },
        _disabled: {
          background: colorScheme === 'white' ? 'neutral.0' : 'transparent',
          color: 'neutral.300',
          opacity: 1,
        },
      };
    },
    outlined: ({theme}) => {
      const {colors} = theme;
      return getOutlinedStyle(colors);
    },
    solid: ({theme}) => {
      const {colors} = theme;
      return {
        background: 'neutral.0',
        border: '1px',
        borderColor: 'neutral.800',
        color: 'neutral.800',
        _hover: {
          background: 'neutral.200',
          borderColor: 'neutral.900',
          color: 'neutral.900',
          _disabled: {
            background: 'neutral.0',
            borderColor: 'neutral.300',
            color: 'neutral.300',
            opacity: 1,
          },
        },
        _active: {
          background: 'neutral.200',
          borderColor: 'neutral.900',
          color: 'neutral.900',
        },
        _focusVisible: {
          boxShadow: `inset 0 0 0 2px ${colors.neutral['800']}`,
        },
        _disabled: {
          background: 'neutral.0',
          borderColor: 'neutral.300',
          color: 'neutral.300',
          opacity: 1,
        },
      };
    },
    danger: ({theme}) => {
      const {colors} = theme;
      return {
        background: 'neutral.0',
        border: '1px',
        borderColor: 'error.600',
        color: 'error.600',
        _hover: {
          background: 'error.600',
          borderColor: 'error.600',
          color: 'neutral.0',
          _disabled: {
            background: 'neutral.100',
            borderColor: 'neutral.100',
            color: 'neutral.600',
            opacity: 1,
          },
        },
        _active: {
          background: 'neutral.0',
          borderColor: 'error.600',
          color: 'error.600',
          boxShadow: `0px 0px 0px 4px ${colors.error['100']}`,
          _disabled: {
            background: 'neutral.100',
            borderColor: 'neutral.100',
            color: 'neutral.600',
            opacity: 1,
            boxShadow: 'none',
          },
        },
        _focusVisible: {
          color: 'neutral.0',
          background: 'error.600',
          outline: '2px solid',
          outlineColor: 'error.600',
          outlineOffset: '3px',
          _disabled: {
            background: 'neutral.100',
            borderColor: 'neutral.100',
            color: 'neutral.600',
            outline: 'none',
            opacity: 1,
          },
        },
        _disabled: {
          background: 'neutral.100',
          borderColor: 'neutral.100',
          color: 'neutral.600',
          opacity: 1,
        },
      };
    },
  },
  sizes: {
    small: ({variant}) => {
      const baseStyle = {
        height: '24px',
        px: 12,
        borderRadius: 6,
        fontSize: 'tiny',
      };
      if (variant === 'icon') {
        return {...baseStyle, width: '24px'};
      }
      return baseStyle;
    },
    medium: ({variant}) => {
      const baseStyle = {
        height: '32px',
        px: 16,
        borderRadius: 8,
        fontSize: 'small',
      };
      if (variant === 'icon') {
        return {...baseStyle, fontSize: 'medium', width: '32px'};
      }
      return baseStyle;
    },
    large: ({variant}) => {
      const baseStyle = {
        height: '44px',
        px: 20,
        borderRadius: '8',
        fontSize: 'small',
      };
      if (variant === 'icon') {
        return {...baseStyle, width: '44px', fontSize: '24px'};
      }
      return baseStyle;
    },
    extralarge: ({variant}) => {
      const baseStyle = {
        height: '52px',
        py: 22,
        borderRadius: '8',
        fontSize: 'small',
      };
      if (variant === 'icon') {
        return {...baseStyle, width: '44px', fontSize: '24px'};
      }
      return baseStyle;
    },
  },
};

const getIconSpacing = (size: TSize, children?: React.ReactNode) => {
  if (!children) return 0;
  const iconSpacing = {
    small: 4,
    medium: 8,
    large: 8,
    extralarge: 8,
  };
  return iconSpacing[size];
};

export const Button = forwardRef((props: TButtonProps, ref) => {
  const {variant, children, tooltipLabel, tooltipPlacement = 'top'} = props;
  const {size = 'medium', ...propsWithoutSize} = props;

  if (variant === 'icon') {
    return (
      <Tooltip label={tooltipLabel} placement={tooltipPlacement}>
        <IconButton ref={ref} {...props} />
      </Tooltip>
    );
  }
  return (
    <Tooltip label={tooltipLabel} placement={tooltipPlacement}>
      <ChakraButton
        ref={ref}
        size={size}
        iconSpacing={getIconSpacing(size, children)}
        {...propsWithoutSize}
      />
    </Tooltip>
  );
});
