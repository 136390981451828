import debounce from 'debounce-promise';
import {useFormContext, useWatch} from 'react-hook-form';
import {team} from 'spekit-datalayer';
import {Box, ControlledSelect} from 'spekit-ui';

export const TeamSelector = () => {
  const {control} = useFormContext();
  const allTeamsState = useWatch({control, name: 'allTeams'});

  const getTeamOptions = async (search = '') => {
    const teamSuggestions = await team.getAssignableTeamSuggestions(search);
    return teamSuggestions.map((item) => ({
      value: item.value,
      label: item.label,
    }));
  };

  if (allTeamsState) {
    return null;
  }
  return (
    <Box>
      <ControlledSelect
        name='teams'
        control={control}
        loadOptions={debounce(getTeamOptions, 200)}
        placeholder='Select Teams'
        id='teams'
        label='Teams'
        isMulti
        isAsync
        cacheOptions
        defaultOptions
      />
    </Box>
  );
};
