import {ITopic} from 'spekit-types';

type TopicModalState = {
  toggle: boolean;
  term: ITopic | null;
};

type IShowTopicModalAction = {
  type: 'SHOWTOPICMODAL';
  term: ITopic;
};

type ICloseTopicModalAction = {
  type: 'CLOSETOPICMODAL';
};

type TopicModalAction = IShowTopicModalAction | ICloseTopicModalAction;

const initialState: TopicModalState = {
  toggle: false,
  term: null,
};

function topicModalReducer(
  state: TopicModalState = initialState,
  action: TopicModalAction
): TopicModalState {
  switch (action.type) {
    case 'SHOWTOPICMODAL':
      return {
        ...state,
        toggle: true,
        term: action.term,
      };
    case 'CLOSETOPICMODAL':
      return {
        ...state,
        toggle: false,
      };
    default:
      return state;
  }
}

export default topicModalReducer;
