import {logging} from 'spekit-datalayer';
import store from '../reduxStore';

export const identify = (userId, traits, options, callback) => {
  try {
    window.analytics?.identify(userId, traits, options, callback);
  } catch (err) {
    logging.capture(err);
  }
};

export const identifySession = (session) => {
  identify(session.id, {
    company: session.company.id,
  });
  logging.setContext('Session', session);
  logging.setUser({
    id: session.id,
    email: session.email,
    company: session.company.name,
  });
};

export const track = (events, properties, options, callback) => {
  const state = store.getState();
  if (state && state.layout && state.layout.me && state.layout.me.impersonated) {
    if (events !== 'Stop Impersonating') {
      return;
    }
  }
  const userId = state?.layout?.me?.id || '';
  try {
    window.analytics.track(
      events,
      {
        ...properties,
        source: properties.source || 'Webapp',
        user_id: userId,
      },
      options,
      callback
    );
  } catch (err) {
    logging.capture(err);
  }
};

export const page = () => {
  try {
    window.analytics.page({source: 'Webapp'});
  } catch (err) {
    logging.capture(err);
  }
};

export const reset = () => {
  try {
    window.analytics.reset();
  } catch (err) {
    logging.capture(err);
  }
};
