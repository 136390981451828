import {Flex, Icon, Text, DSTooltip as Tooltip, Box} from 'spekit-ui';
import {RiInformationLine} from 'react-icons/ri';
import {AllTeamsSelector} from './AllTeamsSelector';
import {TeamSelector} from './TeamSelector';
import {TeamsPermissions} from './TeamsPermissions';

interface IAudienceProps {
  handleClose: () => void;
}

export const Audience = ({handleClose}: IAudienceProps) => {
  return (
    <>
      <Flex flexDirection='column' width='50%' gap={16}>
        <Flex alignItems='center' gap={4}>
          <Text variant='body1' fontWeight={600}>
            Audience
          </Text>
          <Tooltip
            label={`Only selected Teams will be able to view this Topic and the content within it.`}
            placement='bottom'
            shouldWrapChildren
          >
            <Box display='flex' alignItems='center'>
              <Icon
                as={RiInformationLine}
                color='neutral.800'
                height='16px'
                width='16px'
              />
            </Box>
          </Tooltip>
        </Flex>
        <AllTeamsSelector />
        <TeamSelector />
      </Flex>
      <TeamsPermissions handleClose={handleClose} />
    </>
  );
};
