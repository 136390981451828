import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {getExtensionButton} from 'spekit-ui';
import {utils} from 'spekit-datalayer';
import {isComponentVisible, TElementWithVisibility} from 'spekit-shared-components';
import {
  createSpekWithTopic,
  showCreateSpekModal,
  showAssetUploadModal,
  showTopicModal,
  showSpotlightModal,
  showContentImportModal,
} from '../../redux/actions';
import {RootState} from '../../reduxStore';
import {track} from '../../utils/analytics';
import {TMenuType} from 'spekit-types';

const {getExtensionId} = getExtensionButton;

export function useAppNavigation() {
  const session = useSelector((state: RootState) => state.layout.me);
  const dispatch = useDispatch();
  const flags = useSelector((state: RootState) => state.flag);
  const location = useLocation();
  const history = useHistory();
  const notificationCount = useSelector(
    (state: RootState) => state.pushNotifications.count
  );

  const onAskExpert = () => {
    track('Asked a question from webapp');
    const emailDetails = {
      email: session.company.support_email,
      companyName: session.company.name,
      pathName: window.location,
    };
    let userData = {
      currentURL: `${location.pathname}`,
      eventName: 'Question asked',
    };
    track('Question asked from Web App', userData);
    let subject = `Question for team ${emailDetails.companyName} via Spekit`;
    const url = `mailto:${emailDetails.email}?subject=${encodeURIComponent(subject)}`;
    window.open(url, '_parent');
  };

  const handleContentCreatorSelection = async (selection: TMenuType) => {
    switch (selection) {
      case 'spek':
        const queryParams = utils.parseQs(location.search);
        if (queryParams.topic && queryParams.tag) {
          dispatch(
            createSpekWithTopic(
              [decodeURIComponent((queryParams.tag as string).trim())],
              queryParams.topic
            )
          );
        }

        dispatch(showCreateSpekModal());
        track('Create Spek selected', {screen_name: 'Content Creator'});
        setTimeout(() => {
          const el = document.getElementById(
            'spek-creation-modal'
          ) as TElementWithVisibility;
          isComponentVisible({
            element: el,
            message: 'Spek create modal is not visible',
            tag: 'web-spek-create-modal',
          });
        }, 1000);
        break;

      case 'file':
        dispatch(showAssetUploadModal());
        track('Upload File selected', {screen_name: 'Content Creator'});
        break;

      case 'topic':
        dispatch(showTopicModal(null));
        track('Create Topic selected', {screen_name: 'Content Creator'});
        break;

      case 'spotlight':
        dispatch(showSpotlightModal());
        track('Create Spotlight selected', {screen_name: 'Content Creator'});
        break;

      case 'flow':
        const extensionId = await getExtensionId();
        /*global chrome*/
        if (extensionId) {
          chrome.runtime.sendMessage(extensionId, {action: 'record'});
        } else {
          history.push('/app/wiki/topics');
        }
        track('Create Flow selected', {screen_name: 'Content Creator'});
        break;

      case 'knowledge-check':
        history.push('/app/knowledge-checks/create');
        track('Create Knowledge Check selected', {screen_name: 'Create Menu'});
        break;

      case 'content-integration':
        dispatch(showContentImportModal());
        track('File Import Clicked', {screen_name: 'Content Creator'});
        break;

      default:
        break;
    }
  };

  return {
    session,
    onAskExpert,
    handleContentCreatorSelection,
    history,
    flags,
    notificationCount,
  };
}
