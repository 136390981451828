import {Controller, useFormContext} from 'react-hook-form';
import {Flex, Switch, Text, DSTooltip as Tooltip, Box, Icon} from 'spekit-ui';
import {RiInformationLine} from 'react-icons/ri';
import {hasSomeOf} from '../../../../utils/permissions';
import type {ITopicModalState} from './TopicModal';

export const AllTeamsSelector = () => {
  const {control} = useFormContext<ITopicModalState>();
  const canEditAllTeams = hasSomeOf(['teams.update_all']);

  if (!canEditAllTeams) {
    return null;
  }

  return (
    <Flex alignItems='center' ml={4}>
      <Controller
        control={control}
        name='allTeams'
        render={({field: {value, onChange}}) => (
          <Switch
            isChecked={value}
            onChange={onChange}
            data-testid='visible-to-all-switch'
          />
        )}
      />
      <Text variant='body2' fontWeight='normal' ml={8}>
        Visible to all Teams
      </Text>
      <Tooltip
        label={`Includes all current and future Teams.`}
        placement='bottom'
        shouldWrapChildren
      >
        <Box display='flex' alignItems='center' ml={4}>
          <Icon as={RiInformationLine} color='neutral.800' height='16px' width='16px' />
        </Box>
      </Tooltip>
    </Flex>
  );
};
