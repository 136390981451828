import React from 'react';
import {CreateSpekForm} from 'spekit-shared-components/src/speks/spekForm/CreateSpekForm';
import {useSelector} from 'react-redux';
import {DSModal as Modal} from 'spekit-ui';
import {RootState} from '../reduxStore';

const BusinessTermFormFlagged = (props: any) => {
  const state = useSelector((state: RootState) => state);
  const {flag, cloneSpek} = state;
  const {hasAIAIEditorFlag, hasSpekLinkGenerationFlag} = flag;

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size='lg' trapFocus={false}>
      <CreateSpekForm
        {...props}
        termToClone={cloneSpek.termToClone}
        user={props.me}
        hasAIAIEditor={hasAIAIEditorFlag}
        hasSpekLinkGeneration={hasSpekLinkGenerationFlag}
      />
    </Modal>
  );
};

export default BusinessTermFormFlagged;
