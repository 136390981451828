import {Flex, Text, DSTooltip as Tooltip, Box, Icon, Tag, Skeleton} from 'spekit-ui';
import {RiInformationLine, RiLockLine} from 'react-icons/ri';
import {hasSomeOf} from '../../../../utils/permissions';
import {logging, team} from 'spekit-datalayer';
import {useEffect, useState} from 'react';
import {TeamDetail} from 'spekit-datalayer/src/team/team.types';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../reduxStore';

interface ITeamsPermissionsProps {
  handleClose: () => void;
}

export const TeamsPermissions = ({handleClose}: ITeamsPermissionsProps) => {
  const canUpdateAllTeams = hasSomeOf(['teams.update_all']);
  const {role} = useSelector((state: RootState) => state.layout.me);
  const hasAllTopicsAccess = hasSomeOf([
    'teams.add_own',
    'teams.update_own',
    'teams.delete_own',
  ]);
  const [teamsWithAllTopicsAccess, setTeamsWithAllTopicsAccess] = useState<TeamDetail[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTeamsWithAllTopicsAccess = async () => {
      try {
        const result = await team.getTeamsWithAllTopicsAccess();
        setTeamsWithAllTopicsAccess(result.results);
        setIsLoading(false);
      } catch (error) {
        logging.capture(error);
        setIsLoading(false);
      }
    };

    getTeamsWithAllTopicsAccess();
  }, []);

  const handleTeamClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    handleClose();
  };

  return (
    <Flex direction='column' gap={12} mt={24}>
      {canUpdateAllTeams && (
        <Flex alignItems='center' gap={4}>
          <Text variant='body2' fontWeight='semibold'>
            Teams that have permission to access ALL Topics
          </Text>
          <Tooltip
            label={`These are Teams that have access to all Topics based on their admin configured settings.`}
            placement='bottom'
            shouldWrapChildren
          >
            <Box display='flex' alignItems='center'>
              <Icon
                as={RiInformationLine}
                color='neutral.800'
                height='16px'
                width='16px'
              />
            </Box>
          </Tooltip>
        </Flex>
      )}
      <Flex align='center' flexWrap='wrap' gap={6}>
        {isLoading &&
          Array.from({length: 20}).map((_, index) => (
            <Skeleton
              borderRadius={6}
              height='24px'
              width={`${Math.floor(Math.random() * (150 - 50 + 1)) + 50}px`}
              key={index}
              speed={1.5}
            />
          ))}
        {teamsWithAllTopicsAccess.map((value) => {
          if (!value.system && hasAllTopicsAccess) {
            return (
              <Link
                to={{
                  pathname: `/app/settings/access/teams/${value.id}`,
                  state: value,
                }}
                style={{
                  textDecoration: 'none',
                  cursor: role === 'Expert' ? 'revert' : 'pointer',
                }}
                key={value.id}
                onClick={handleTeamClick}
              >
                <Tag
                  variant={role === 'Expert' ? 'status' : 'filter'}
                  colorScheme='neutral'
                >
                  {value.name}
                </Tag>
              </Link>
            );
          }
          return (
            <Flex key={value.id} align='center' gap={4} data-testid='locked-team'>
              <Icon as={RiLockLine} />
              <Text variant='caption1' fontWeight='semibold'>
                {value.name}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
