import React, {useState, useEffect, useRef} from 'react';
import {Flex, Box} from '../../Layout';
import {Heading, Text} from '../../Typography';
import {Loader} from '../../Loader/Loader';

import {CONTENT_SHARING, getCompanySharingSetting} from 'spekit-datalayer';

// we use this component for both spek and system level permissions
// The design is slightly different for each, and the system level setting is toggled via api in the onChange, so we use a variant prop to determine which to use
type TVariant = 'system' | 'spek' | 'asset';

import {Alert} from '../../Alert/Alert';
import {AlertDescription} from '../../Alert/AlertDescription';
import {Tooltip} from '../../Tooltip';
import {Icon} from '../../Icon';
import {RiInformationLine} from 'react-icons/ri';
import {Checkbox} from '../../Checkbox';

export interface IPrivacySettings {
  tooltipLabel?: string;
  alertMessage?: string;
  variant?: TVariant;
  children?: React.ReactNode;
  isDisabled?: boolean;

  // each setting needs a value and setter
  isSharingChecked: boolean;
  setIsSharingChecked: (isChecked: boolean) => void;
  isAllowDownloadChecked?: boolean;
  setIsAllowDownloadChecked?: (isChecked: boolean) => void;
}

const headingText = 'Share settings';

export const PrivacySettings = ({
  tooltipLabel,
  children,
  isSharingChecked,
  setIsSharingChecked,
  isAllowDownloadChecked,
  setIsAllowDownloadChecked,
  alertMessage,
  variant = 'spek',
  isDisabled,
}: IPrivacySettings) => {
  // we do a few different things based on the variant, but the overall design is the same
  const isSystemPrivacy = variant === 'system';

  // we don't want to warn the user if the value starts falsy, only when they toggle it off if it loaded as true
  // the system privacy functions a little differently, so we store a different initial value
  const initialValue = useRef(
    isSystemPrivacy && isSharingChecked === false ? null : isSharingChecked
  );
  const [showAlert, setShowAlert] = useState(false);

  // we need to check if the company allows external sharing
  const [externalShareEnabled, setExternalShareEnabled] = React.useState<null | boolean>(
    null
  );

  useEffect(() => {
    // function to get the company setting
    async function getSharingSetting() {
      const {sharing_allowed} = await getCompanySharingSetting();
      setExternalShareEnabled(sharing_allowed);
    }

    // system level privacy starts as null, so we need to set the real initial value once it loads
    if (initialValue.current === null && isSharingChecked) {
      initialValue.current = isSharingChecked;
    }

    // show the alert if the user toggles an initial true value to false
    if (initialValue.current) {
      // show the alert when toggled off
      setShowAlert(!isSharingChecked);
    }

    // we load the company setting if  we're not in system privacy
    if (externalShareEnabled === null && !isSystemPrivacy) {
      getSharingSetting();
    }

    if (isSystemPrivacy) {
      // system setting is passed from system privacy component
      setExternalShareEnabled(isSharingChecked || false);
    }
  }, [isSharingChecked]);

  const gap = {
    system: 16,
    asset: 16,
    spek: 10,
  };

  const renderHeading = () => {
    if (variant === 'asset' || variant === 'spek') {
      return (
        <Text data-testid='spek-external-share-label' fontWeight='semibold'>
          {headingText}
        </Text>
      );
    }
    // variant = 'system'
    return (
      <Heading
        fontWeight='semibold'
        data-testid='spek-external-share-label-heading'
        as='h4'
        mb={showAlert && alertMessage ? 2 : 10}
      >
        {headingText}
      </Heading>
    );
  };

  return (
    <Flex gap={gap[variant]} direction='column' w='100%' fontWeight='normal'>
      {/* we use this component in multiple places, with different heading requirements */}
      {renderHeading()}
      {/* alert when toggled off */}
      {showAlert && alertMessage && (
        <Alert
          data-testid='privacy-alert'
          variant='warning'
          onClose={() => setShowAlert(false)}
          maxW='none'
          mt={!isSystemPrivacy ? 4 : 0}
          mb={!isSystemPrivacy ? 4 : 0}
        >
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}

      {/* loading state */}
      {externalShareEnabled === null ? (
        <Box data-testid='privacy-loader'>
          <Loader variant='pulse' size={10} />
        </Box>
      ) : externalShareEnabled || isSystemPrivacy ? (
        // system, or enabled state
        <Flex flexDir='column' gap={8}>
          <Flex alignItems='center'>
            <Checkbox
              data-testid='asset-shareable-switch'
              isChecked={isSharingChecked}
              onChange={() => {
                setIsSharingChecked(!isSharingChecked);
              }}
              isDisabled={isDisabled}
              aria-label={
                isSystemPrivacy
                  ? CONTENT_SHARING.SYSTEM_SHARING
                  : CONTENT_SHARING.ALL_USERS
              }
            />
            <Text variant='body2'>
              {isSystemPrivacy
                ? CONTENT_SHARING.SYSTEM_SHARING
                : CONTENT_SHARING.ALL_USERS}
            </Text>
            {tooltipLabel && (
              <Tooltip label={tooltipLabel} placement='top'>
                <Flex
                  ml={4}
                  alignItems='center'
                  justifyContent='center'
                  data-testid='tooltip-icon'
                >
                  <Icon as={RiInformationLine} h={4} w={4} />
                </Flex>
              </Tooltip>
            )}
          </Flex>
          {isSharingChecked &&
            setIsAllowDownloadChecked &&
            isAllowDownloadChecked !== undefined && (
              <Flex ml={20} alignItems='center'>
                <Checkbox
                  data-testid='asset-downloadable-switch'
                  isChecked={isAllowDownloadChecked}
                  onChange={() => {
                    setIsAllowDownloadChecked(!isAllowDownloadChecked);
                  }}
                  isDisabled={isDisabled}
                  aria-label={CONTENT_SHARING.ALLOW_DOWNLOAD}
                />
                <Text variant='body2'>{CONTENT_SHARING.ALLOW_DOWNLOAD}</Text>
              </Flex>
            )}
        </Flex>
      ) : (
        // disabled state
        <Text variant='body2'>External sharing has been disabled for this content.</Text>
      )}
    </Flex>
  );
};
