import React, {useState, useEffect} from 'react';
import {PrivacySettings} from 'spekit-ui';
import {
  toggleCompanySharingSetting,
  getCompanySharingSetting,
  logging,
} from 'spekit-datalayer';
import {useDispatch} from 'react-redux';

/**
 * System Privacy Settings
 * This is a standalone component that is only visible to admins
 * This component toggles the external_sharing_allowed company setting
 */
export const SystemPrivacy = () => {
  const [sharingAllowed, setSharingAllowed] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // check if sharing is allowed
    async function getSharingSetting() {
      try {
        const {sharing_allowed} = await getCompanySharingSetting();
        setSharingAllowed(sharing_allowed);
        dispatch({type: 'SET_SHARING_PRIVACY', payload: sharing_allowed});
      } catch (e) {
        logging.capture(e);
      }
    }
    getSharingSetting();
  }, [dispatch]);

  const handleToggle = async () => {
    const {sharing_allowed} = await toggleCompanySharingSetting();
    setSharingAllowed(sharing_allowed);
    dispatch({type: 'SET_SHARING_PRIVACY', payload: sharing_allowed});
  };

  return (
    <PrivacySettings
      tooltipLabel='External share links can be accessed by anyone with the link. When enabled, this setting can also be configured at the individual content level.'
      data-testid='spek-external-share-admin-control'
      isSharingChecked={sharingAllowed}
      setIsSharingChecked={handleToggle}
      variant='system'
      alertMessage='All existing external content links will no longer work when external sharing is turned off.'
    />
  );
};
