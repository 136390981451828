import {
  IGetRecommendationsPayload,
  TGetRecommendationsResponse,
  TGetSummariesResponse,
  TGetChatResponse,
  TFeedbackQuestionsResponse,
  TFollowUpQuestionResponse,
  RexApp,
  IChatMessage,
  TChatHistory,
} from 'spekit-types';
import {create, retrieve} from '../utils/APIHelpers';
import {
  SPEKIT_RECOMMENDATION_API,
  SPEKIT_CHAT_API,
  CHATBOT_FEEDBACK_QUESTIONS_API,
  SPEKIT_CHAT_FOLLOWUP_API,
} from '../api';

export const getContextualRecommendations = async (
  payload: IGetRecommendationsPayload
) => {
  const response: Promise<TGetRecommendationsResponse> = create(
    `${SPEKIT_RECOMMENDATION_API}`,
    payload
  );
  return response;
};

export const getChatResponse = async (
  message: string,
  chatHistory: TChatHistory[]
): Promise<TGetChatResponse> => {
  const response: TGetChatResponse = await create(`${SPEKIT_CHAT_API}`, {
    message,
    context: message,
    engine: 'default-metarerank',
    chat_history: chatHistory,
  });
  return response;
};

export const getFeedbackQuestions = async (): Promise<TFeedbackQuestionsResponse> => {
  return retrieve(CHATBOT_FEEDBACK_QUESTIONS_API);
};

export const getFollowUpQuestions = async (
  question: string,
  answer: string,
  sources: string[],
  chatHistory: TChatHistory[]
): Promise<TFollowUpQuestionResponse> => {
  const response: TFollowUpQuestionResponse = await create(
    `${SPEKIT_CHAT_FOLLOWUP_API}`,
    {
      question,
      answer,
      sources,
      chat_history: chatHistory,
    }
  );
  return response;
};
