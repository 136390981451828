/**
 * This file contains all the feature flags used in the webapp and extension.
 * These include constants used for chrome storage and conditional UI rendering.
 *
 * To add a new flag, add it to the enum below in `PascalCase`.
 *
 * Any flag defined here will be automatically added to the redux store (both, action and reducer) as is
 * in the webapp with the default value false unless enabled.
 * The flag in the redux store will be of the form `hasXFlag`..
 */

export enum FLAGS {
  Algolia = 'Algolia',
  Axios = 'Axios',
  AIAIAssistUserGeneratedFeedback = 'AI-AIAssist-UserGeneratedFeedback',
  ContentIntegration = 'ContentIntegration',
  ContentIntegrationSharepoint = 'ContentIntegrationSharepoint',
  ContentIntegrationSalesforce = 'ContentIntegrationSalesforce',
  ContentIntegrationConfluence = 'ContentIntegrationConfluence',
  DisableGotoSourceBtn = 'DisableGotoSourceBtn',
  CustomReportsDashboard = 'CustomReportsDashboard',
  EmptyFrameBlocker = 'EmptyFrameBlocker',
  Flow = 'Flow',
  AIContentRecommendationsAllDomains = 'AI-ContentRecommendations-AllDomains',
  KnowledgeChecks = 'Quiz',
  MultiOrg = 'MultiOrg',
  NewSearch = 'NewSearch',
  Quiz = 'Quiz',
  AIIndexContentIntoPinecone = 'AI-IndexContentIntoPinecone',
  AIContentRecommendations = 'AI-ContentRecommendations',
  AIAIAssist = 'AI-AIAssist',
  Seismic = 'Seismic',
  SpekBehaviour = 'DefaultSpekPopoversToOnClick',
  AIAIEditor = 'AI-AIEditor',
  SpekitShare = 'SpekitShareFiles',

  SpekLinkGeneration = 'SmartSendLinks',
  Spotlight = 'Spotlight',

  SSO = 'SSO',
  SmartSendROI = 'SmartSendROI',
  NestedTopics = 'NestedTopics',

  AIAssistDefaultViewInSidebar = 'AI-AIAssist-DefaultViewInSidebar',
}
