import React, {useReducer, useState, useRef, useEffect} from 'react';
import {
  Fontawesome,
  Accordion,
  AccordionItem,
  DSTooltip as Tooltip,
  Discard,
  Box,
  Confirmation,
  ModalTitlebar,
  Title,
} from 'spekit-ui';
import styles from './spotlightModal.module.css';
import {faInfoCircle, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import SpotlightActions from './spotlightActions';
import Create from './Create';
import {Publish} from '../commons';
import {Spotlight, utils, add, parseISO, isSameOrAfter} from 'spekit-datalayer';
import {PropagateLoader} from 'react-spinners';
import {isError} from './errors';
import {Action, State} from './types';
import Analyze from './analyze';
import SpotlightStatusIndicator from './SpotlightStatusIndicator/SpotlightStatusIndicator';
import moment from 'moment/moment';
import SpotlightUtilityBar from './SpotlightUtilityBar/SpotlightUtilityBar';
import PublishSettings from './PublishSettings/PublishSettings';

const {create, update, unPublishSpotlight, deleteSpotlight, cloneSpotlight} = Spotlight;

const initialState: State = {
  headline: '',
  message: '',
  image: null,
  embed: '',
  system_image: 'Graphic1',
  loading: false,
  image_loading: false,
  spekitLibrary: false,
  embedPopover: false,
  is_published: false,
  isSaving: false,
  teams: [],
  audienceRules: [],
  is_changed: false,
  is_new: true,
  edit_mode: false,
  read_mode: false,
  teamError: false,
  targeting: [{type: null}],
  engagements: {
    label: 'Got it',
    type: 'acknowledge-and-close',
    content: {},
  },
  host: '',
  track: () => {},
  screen_name: 'Spotlight Modal',
  headlineError: false,
  messageError: false,
  engagementLabelError: false,
  actionType: null,
  actionTypeError: false,
  engagementContentError: false,
  spekitResourceError: false,
  errors: {
    engagementUrl: false,
    engagementUrlText: '',
  },
  notify: null,
  published_on: null,
  autolaunch: true,
  end_on: add(Date.now(), {days: 14}),
  isNeverExpires: false,
  isExpired: false,
  messageCharactersCount: 0,
  spotlightHeadlineCount: 0,
  previewDescriptionHeight: 0,
  publishError: '',
};

const spotlightReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_MESSAGE':
      return {...state, message: action.payload, is_changed: true, messageError: false};
    case 'SET_HEADLINE_CHARACTERS_COUNT':
      return {
        ...state,
        spotlightHeadlineCount: action.payload,
        is_changed: true,
        messageError: false,
      };
    case 'SET_MESSAGE_CHARACTERS_COUNT':
      return {
        ...state,
        messageCharactersCount: action.payload,
        is_changed: true,
        messageError: false,
      };
    case 'SET_PREVIEW_DESCRIPTION_HEIGHT':
      return {
        ...state,
        previewDescriptionHeight: action.payload,
        messageError: false,
      };
    case 'SET_HEADLINE':
      return {...state, headline: action.payload, is_changed: true, headlineError: false};
    case 'SET_IMAGE':
      return {
        ...state,
        image: action.payload,
        image_loading: false,
        embed: '',
        system_image: '',
        is_changed: true,
      };
    case 'SET_LOADING':
      return {...state, loading: action.payload};
    case 'SET_IMAGE_LOADING':
      return {...state, image_loading: action.payload};
    case 'SET_EMBED':
      return {
        ...state,
        embed: action.payload,
        image: null,
        system_image: '',
        is_changed: true,
      };
    case 'SET_SYSTEM_IMAGE':
      return {
        ...state,
        system_image: action.payload,
        image: null,
        embed: '',
        spekitLibrary: false,
        is_changed: true,
      };
    case 'DELETE_PREVIEW':
      return {...state, system_image: '', image: null, embed: '', is_changed: true};
    case 'OPEN_LIBRARY':
      return {...state, spekitLibrary: true};
    case 'CLOSE_LIBRARY':
      return {...state, spekitLibrary: false};
    case 'OPEN_EMBED':
      return {...state, embedPopover: true};
    case 'CLOSE_EMBED':
      return {...state, embedPopover: false};
    case 'SET_TEAMS':
      return {...state, teams: action.payload, is_changed: true, teamError: false};
    case 'SET_AUDIENCE_RULES':
      return {...state, audienceRules: action.payload, is_changed: true};
    case 'SET_CHANGED_FALSE':
      return {...state, is_changed: false};
    case 'SET_PUBLISH':
      return {
        ...state,
        is_new: false,
        read_mode: true,
        is_published: true,
        is_changed: false,
        isSaving: false,
      };
    case 'SET_DRAFT':
      return {
        ...state,
        is_new: false,
        read_mode: true,
        is_published: false,
        is_changed: false,
        isSaving: false,
        isExpired: false,
      };
    case 'SET_UNPUBLISH':
      return {...state, is_published: false, is_changed: true};
    case 'SET_TARGETING_RULES':
      return {...state, targeting: action.payload, is_changed: true};
    case 'SET_EDIT_MODE':
      return {...state, read_mode: false, edit_mode: true};
    case 'SET_ENGAGEMENTS':
      return {
        ...state,
        engagements: action.payload,
        is_changed: true,
        engagementLabelError: false,
        engagementContentError: false,
      };
    case 'SET_SAVING':
      return {...state, isSaving: action.payload};
    case 'MAP_SPOTLIGHT_FROM_API':
      const labels = {url: 'Go to URL', 'acknowledge-and-close': 'Acknowledge and close'};
      const actionType = ['acknowledge-and-close', 'url'].includes(
        action.payload.engagements.type
      )
        ? {
            value: action.payload.engagements.type,
            label: labels[action.payload.engagements.type],
          }
        : {value: 'spekit-resource', label: 'Go to Spekit resource'};
      return {
        ...state,
        ...action.payload,
        end_on:
          action.payload.end_on === null
            ? add(Date.now(), {days: 14})
            : parseISO(action.payload.end_on),
        isNeverExpires: action.payload.end_on === null,
        read_mode: true,
        is_new: false,
        loading: false,
        actionType: actionType,
      };
    case 'RESET_STATE':
      return {
        ...initialState,
      };
    case 'RESET_TARGETING':
      return {
        ...state,
        targeting: [{type: null}],
      };
    case 'SET_HOST':
      return {
        ...state,
        host: action.payload.host,
      };
    case 'SET_HEADLINE_ERROR':
      return {
        ...state,
        headlineError: action.payload,
      };
    case 'SET_MESSAGE_ERROR':
      return {
        ...state,
        messageError: action.payload,
      };
    case 'SET_LABEL_ERROR':
      return {
        ...state,
        engagementLabelError: action.payload,
      };
    case 'SET_ACTION_TYPE':
      return {
        ...state,
        actionType: action.payload,
      };
    case 'SET_ACTION_TYPE_ERROR':
      return {
        ...state,
        actionTypeError: action.payload,
      };
    case 'SET_SPEKIT_RESOURCE_ERROR':
      return {
        ...state,
        spekitResourceError: action.payload,
      };
    case 'SET_ENGAGEMENT_CONTENT_ERROR':
      return {
        ...state,
        engagementContentError: action.payload,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'SET_NOTIFY':
      return {
        ...state,
        notify: action.payload,
      };
    case 'SET_TEAM_ERROR':
      return {
        ...state,
        teamError: true,
      };
    case 'SET_EXPIRES_ON':
      return {
        ...state,
        end_on: action.payload,
      };
    case 'SET_NEVER_EXPIRES':
      return {
        ...state,
        isNeverExpires: action.payload,
      };
    case 'SET_FLAGS':
      return {
        ...state,
        flag: action.payload,
      };
    case 'SET_SAVING_FALSE':
      return {
        ...state,
        isSaving: false,
      };

    case 'SET_PUBLISH_ERROR':
      return {
        ...state,
        publishError: action.payload,
      };
    default:
      return state;
  }
};

const buildInitialState = ({teams = [], message = ''}: any) => {
  return {...initialState, teams, message};
};

const maxHeadlineLength = 140;
const maxMessageLength = 280;
const maxPreviewDescriptionHeight = 270;

const SpotlightModal = (props: any) => {
  const [state, dispatch] = useReducer(spotlightReducer, buildInitialState(props));
  const [discardPopup, setDiscardPopup] = useState<boolean>(false);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false);
  const [editConfirmationPopup, setEditConfirmationPopup] = useState<boolean>(false);
  const [publishConfirmationPopup, setPublishConfirmationPopup] = useState(false);
  const [publishConfirmationPopupOnClose, setPublishConfirmationPopupOnClose] =
    useState(false);
  const [editDatePopup, setEditDatePopup] = useState(false);

  const {knowledgeCheck, flow, fileDetails, flag} = props;
  const containerRef = useRef(null);
  let termId: string | null = null;
  const {notify} = props;
  const query = utils.parseQs(props?.location?.search);

  useEffect(() => {
    if (props.withTerm) {
      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          type: 'term',
          label: 'View Spek',
          content: {
            business_term: {
              value: props.withTerm.termId,
              label: props.withTerm.termLabel,
              term_type: 'business_term',
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_ENGAGEMENTS',
      payload: {
        label: '',
        type: '',
        content: {},
      },
    });

    dispatch({type: 'SET_FLAGS', payload: props.flag});
  }, [props.flag]);

  useEffect(() => {
    dispatch({type: 'SET_NOTIFY', payload: notify});
    if (query.termId && query.term) {
      termId = query.termId as string;

      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          type: 'term',
          label: 'View Spek',
          content: {
            business_term: {
              value: query.termId,
              label: query.term,
              term_type: 'business_term',
            },
          },
        },
      });

      dispatch({
        type: 'SET_ACTION_TYPE',
        payload: {value: 'spekit-resource', label: 'Go to Spekit resource'},
      });
    }

    if (props.host) {
      dispatch({
        type: 'SET_HOST',
        payload: {host: props.host},
      });
    }

    if (props.spotlightId) {
      dispatch({type: 'SET_LOADING', payload: true});
      (async () => {
        let spotlight = await Spotlight.getSpotlight(props.spotlightId);
        dispatch({type: 'MAP_SPOTLIGHT_FROM_API', payload: spotlight});
      })();
    }
  }, []);

  /**
   * This effect is ran when modal is opened
   * with action createSpotlightWithKnowledgeCheck otherwise
   * Knowledge Check is null.
   */
  useEffect(() => {
    if (knowledgeCheck) {
      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          content: {
            quiz: {
              value: knowledgeCheck.id,
              label: knowledgeCheck.title,
            },
          },
          label: 'Begin',
          type: 'quiz',
        },
      });

      dispatch({type: 'SET_TEAMS', payload: knowledgeCheck.teams});

      dispatch({
        type: 'SET_ACTION_TYPE',
        payload: {value: 'spekit-resource', label: 'Go to Spekit resource'},
      });
    }
  }, [knowledgeCheck?.id, knowledgeCheck?.title, knowledgeCheck?.teams, dispatch]);

  useEffect(() => {
    if (flow) {
      dispatch({type: 'SET_TEAMS', payload: flow.teams});

      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          content: {
            flow: {
              value: flow.id,
              label: flow.label,
            },
          },
          label: 'Go to Flow',
          type: 'flow',
        },
      });

      dispatch({
        type: 'SET_ACTION_TYPE',
        payload: {value: 'spekit-resource', label: 'Go to Spekit resource'},
      });
    }
  }, [flow]);

  useEffect(() => {
    if (fileDetails) {
      dispatch({
        type: 'SET_ENGAGEMENTS',
        payload: {
          type: 'file',
          label: 'View File',
          content: {
            file: {
              value: fileDetails.value,
              label: fileDetails.label,
            },
          },
        },
      });

      dispatch({
        type: 'SET_ACTION_TYPE',
        payload: {value: 'spekit-resource', label: 'Go to Spekit resource'},
      });
    }
  }, [fileDetails?.value, fileDetails?.label]);

  const checkForErrors = () => {
    let {
        teams,
        message,
        headline,
        engagements,
        targeting,
        messageCharactersCount,
        spotlightHeadlineCount,
        previewDescriptionHeight,
        actionType,
      } = state,
      isInvalid = false;
    if (!headline || spotlightHeadlineCount >= maxHeadlineLength) {
      dispatch({type: 'SET_HEADLINE_ERROR', payload: true});
      isInvalid = true;
    }
    if (!message || messageCharactersCount >= maxMessageLength) {
      dispatch({type: 'SET_MESSAGE_ERROR', payload: true});
      isInvalid = true;
    }
    if (teams.length === 0) {
      dispatch({type: 'SET_TEAM_ERROR', payload: true});
    }
    if (previewDescriptionHeight > maxPreviewDescriptionHeight) {
      dispatch({type: 'SET_MESSAGE_ERROR', payload: true});
      isInvalid = true;
    }
    if (engagements.type === 'url' && !engagements.content.url) {
      dispatch({
        type: 'SET_ERRORS',
        payload: {engagementUrl: true, engagementUrlText: 'Enter destination URL'},
      });
      isInvalid = true;
    }
    if (!engagements.label) {
      dispatch({type: 'SET_LABEL_ERROR', payload: true});
      isInvalid = true;
    }

    if (!actionType) {
      dispatch({type: 'SET_ACTION_TYPE_ERROR', payload: true});
      isInvalid = true;
    }
    if (engagements.type === '') {
      dispatch({type: 'SET_SPEKIT_RESOURCE_ERROR', payload: true});
      isInvalid = true;
    }
    if (
      engagements.type !== 'acknowledge-and-close' &&
      Object.keys(engagements.content).length === 0
    ) {
      dispatch({type: 'SET_ENGAGEMENT_CONTENT_ERROR', payload: true});
      isInvalid = true;
    }

    if (!targeting.filter((t) => t.type).length) {
      dispatch({
        type: 'SET_PUBLISH_ERROR',
        payload: 'Select where Spotlight should display.',
      });
      dispatch({type: 'SET_SAVING', payload: false});

      return true;
    }

    const targets = targeting.map((t) =>
      !t.domain_value && t.type === 'url'
        ? {...t, error: true, errorText: 'Enter a valid URL.'}
        : t
    );
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    if (isInvalid) {
      dispatch({type: 'SET_SAVING', payload: false});
      return true;
    }

    let {error, text} = isError({
      teams,
      targeting,
    });
    if (error) {
      notify({error, text});
      dispatch({type: 'SET_SAVING', payload: false});
      return true;
    }

    return false;
  };

  const saveAsPublished = async () => {
    setDiscardPopup(false);
    setPublishConfirmationPopup(false);
    dispatch({type: 'SET_SAVING', payload: true});
    if (!checkForErrors()) {
      if (!props.spotlightId && state.is_new) {
        try {
          let spotlight = await create({...state, term_id: termId}, 'published');
          props.track('Spotlight published', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          dispatch({type: 'MAP_SPOTLIGHT_FROM_API', payload: spotlight});
          dispatch({type: 'SET_PUBLISH'});
          notify({
            text: 'Your Spotlight is published and should be live for all users within 10 minutes.',
          });
        } catch (e) {
          notify({error: true, text: e.message});
          dispatch({type: 'SET_SAVING', payload: false});
        }
      } else {
        try {
          await update(state, 'published');
          props.track('Spotlight published', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          state.is_changed &&
            props.track('Spotlight Edited', {
              screen_name: 'Spotlight Modal',
              id: state.id,
              headline: state.headline,
            });
          dispatch({type: 'SET_PUBLISH'});
          notify({
            text: 'Your Spotlight is published and should be live for all users within 10 minutes.',
          });
        } catch (e) {
          notify({error: true, text: e.message});
          dispatch({type: 'SET_SAVING', payload: false});
        }
      }
    }
  };

  const saveAsPublishedOnClose = async () => {
    setPublishConfirmationPopupOnClose(false);
    if (!checkForErrors()) {
      dispatch({type: 'SET_SAVING', payload: true});
      if (!props.spotlightId && state.is_new) {
        try {
          setDiscardPopup(false);
          await create({...state, term_id: termId}, 'published');
          props.track('Spotlight published', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          dispatch({type: 'SET_PUBLISH'});
          notify({
            text: 'Your Spotlight is published and should be live for all users within 10 minutes.',
          });
          props.handleSpotlightClose();
        } catch (e) {
          notify({error: true, text: e.message});
        }
      } else {
        try {
          setDiscardPopup(false);
          await update(state, 'published');
          props.track('Spotlight published', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          state.is_changed &&
            props.track('Spotlight Edited', {
              screen_name: 'Spotlight Modal',
              id: state.id,
              headline: state.headline,
            });
          dispatch({type: 'SET_PUBLISH'});
          notify({
            text: 'Your Spotlight is published and should be live for all users within 10 minutes.',
          });
          props.handleSpotlightClose();
        } catch (e) {
          notify({error: true, text: e.message});
        }
      }
    }
  };

  const saveAsDraft = async () => {
    if (!checkForErrors()) {
      dispatch({type: 'SET_SAVING', payload: true});
      if (!props.spotlightId && state.is_new) {
        try {
          setDiscardPopup(false);
          setEditDatePopup(false);
          let spotlight = await create(state, 'draft');
          props.track('Spotlight draft created', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          dispatch({type: 'MAP_SPOTLIGHT_FROM_API', payload: spotlight});
          dispatch({type: 'SET_DRAFT'});
        } catch (e) {
          notify({error: true, text: e.message});
        }
      } else {
        try {
          setDiscardPopup(false);
          setEditDatePopup(false);
          await update(state, 'draft');
          props.track('Spotlight draft saved by user', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          state.is_changed &&
            props.track('Spotlight Edited', {
              screen_name: 'Spotlight Modal',
              id: state.id,
              headline: state.headline,
            });
          dispatch({type: 'SET_DRAFT'});
        } catch (e) {
          dispatch({type: 'SET_SAVING_FALSE'});
          notify({error: true, text: e.message});
        }
      }
    }
  };

  const saveAsDraftOnClose = async () => {
    if (!checkForErrors()) {
      dispatch({type: 'SET_SAVING', payload: true});
      if (!props.spotlightId && state.is_new) {
        try {
          setDiscardPopup(false);
          setEditDatePopup(false);
          await create(state, 'draft');
          props.track('Spotlight draft created', {
            screen_name: 'Spotlight Modal',
            headline: state.headline,
          });
          dispatch({type: 'SET_DRAFT'});
          props.handleSpotlightClose();
        } catch (e) {
          notify({error: true, text: e.message});
        }
      } else {
        try {
          setDiscardPopup(false);
          setEditDatePopup(false);
          await update(state, 'draft');
          props.track('Spotlight draft saved by user', {
            screen_name: 'Spotlight Modal',
            id: state.id,
            headline: state.headline,
          });
          state.is_changed &&
            props.track('Spotlight Edited', {
              screen_name: 'Spotlight Modal',
              id: state.id,
              headline: state.headline,
            });
          dispatch({type: 'SET_DRAFT'});
          props.handleSpotlightClose();
        } catch (e) {
          dispatch({type: 'SET_SAVING_FALSE'});
          notify({error: true, text: e.message});
        }
      }
    }
  };

  const unpublishHandler = async () => {
    try {
      await unPublishSpotlight(state.id);
      props.track('Spotlight unpublished by user', {
        screen_name: 'Spotlight Modal',
        id: state.id,
        headline: state.headline,
      });
      dispatch({type: 'SET_UNPUBLISH'});
    } catch (e) {}
  };

  const handleDiscard = () => {
    setDiscardPopup(false);
    props.handleSpotlightClose();
  };

  const handleClose = () => {
    if (state.is_changed) {
      setDiscardPopup(true);
    } else {
      props.handleSpotlightClose();
    }
    let isEdited = state.edit_mode || state.read_mode;
    props.track(`${isEdited ? 'Edit' : 'Create'} Spotlight Closed`, {
      screen_name: 'Spotlight Modal',
      id: state.id,
      headline: state.headline,
    });
  };

  const openDeleteConfirmation = () => {
    setDeleteConfirmationPopup(true);
  };

  const handleDelete = async () => {
    try {
      await deleteSpotlight(state.id);
      notify({error: false, text: 'Spotlight deleted successfully.'});
      props.track('Spotlight draft deleted', {
        screen_name: 'Spotlight Modal',
        id: state.id,
        headline: state.headline,
      });
      props.handleSpotlightDelete();
    } catch (e) {
      notify({error: true, text: 'Unable to delete spotlight.'});
    }
  };

  const openEditConfirmation = () => {
    setEditConfirmationPopup(true);
  };

  const handleEdit = () => {
    if (state.is_published) {
      unpublishHandler();
    }
    dispatch({type: 'SET_EDIT_MODE'});
    setEditConfirmationPopup(false);
    props.track('Edit Spotlight opened', {screen_name: 'Spotlight Modal'});
  };

  const handleCancel = () => {
    setDiscardPopup(false);
  };

  const handleClone = async () => {
    dispatch({type: 'SET_LOADING', payload: true});
    try {
      let clonedSpotlight = await cloneSpotlight(state.id, `[CLONED] ${state.headline}`);
      let spotlight = await Spotlight.getSpotlight(clonedSpotlight.clone_spotlight_id);
      notify({text: 'Spotlight cloned successfully.'});
      dispatch({type: 'MAP_SPOTLIGHT_FROM_API', payload: spotlight});
    } catch (e) {
      dispatch({type: 'SET_LOADING', payload: false});
      notify({error: true, text: 'There was an error cloning the Spotlight.'});
    }
  };

  const status = () => {
    if (state.isSaving) return 'saving';
    if (state.is_published) return 'published';
    if (state.isExpired) return 'expired';
    return 'draft';
  };

  const handleAccordionToggle = (expanded: boolean | undefined, title: string) => {
    props.track(`Spotlight section ${!expanded ? 'opened' : 'closed'}`, {
      section_name: title,
    });
  };

  const marketingLink = () => (
    <a
      href='https://help.spekit.com/hc/en-us/articles/9922799127067-How-do-I-create-Spotlights-'
      target='_blank'
      className={styles.link}
    >
      Learn how Spotlights work
      <Fontawesome name={faAngleRight} className={styles.icon} />
    </a>
  );

  const openPublishConfirmation = () => {
    const endOn = moment.isMoment(state.end_on) ? state.end_on.toDate() : state.end_on;
    if (!state.isNeverExpires && isSameOrAfter(Date.now(), endOn)) {
      setEditDatePopup(true);
    } else if (state.published_on) {
      setPublishConfirmationPopup(true);
    } else {
      saveAsPublished();
    }
  };

  const openPublishConfirmationOnClose = () => {
    if (!state.isNeverExpires && isSameOrAfter(Date.now(), state.end_on)) {
      setEditDatePopup(true);
    } else if (state.published_on) {
      setPublishConfirmationPopupOnClose(true);
    } else {
      saveAsPublishedOnClose();
    }
  };

  return (
    <>
      <SpotlightUtilityBar
        isPublished={state.is_published}
        readable={state.read_mode}
        editable={state.edit_mode}
        isNew={props.spotlightId && state.is_new}
        unpublish={unpublishHandler}
        saveDraft={saveAsDraft}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleClone={handleClone}
        isSaving={state.isSaving}
        isExpired={state.isExpired}
        onClose={handleClose}
        isCreate={state.is_new}
        saveAsDraft={saveAsDraft}
        saveAsPublished={saveAsPublished}
        handleDiscard={handleDiscard}
      />

      {discardPopup && (
        <Discard
          saveAsPublished={openPublishConfirmationOnClose}
          saveAsDraft={saveAsDraftOnClose}
          isPublished={state.is_published}
          handleDiscard={handleDiscard}
          handleCancel={handleCancel}
        />
      )}
      {deleteConfirmationPopup && (
        <Confirmation
          heading='Are you sure?'
          subHeading='This will remove this spotlight.'
          firstOptionHandler={handleDelete}
          secondOptionHandler={() => setDeleteConfirmationPopup(false)}
        />
      )}

      {editConfirmationPopup && (
        <Confirmation
          heading='Save as draft?'
          subHeading='Making edits will unpublish this item.'
          firstOptionHandler={handleEdit}
          secondOptionHandler={() => setEditConfirmationPopup(false)}
        />
      )}

      {publishConfirmationPopup && (
        <Confirmation
          heading='This spotlight will only display for users who have not seen it.'
          subHeading='If you want all users to see this spotlight, please make a clone instead'
          firstOptionHandler={saveAsPublished}
          secondOptionHandler={() => setPublishConfirmationPopup(false)}
          firstOptionText='Publish'
          secondOptionText='Cancel'
        />
      )}

      {editDatePopup && (
        <Confirmation
          heading='Your end date has already passed.'
          subHeading='Adjust this to publish this Spotlight.'
          firstOptionHandler={() => setEditDatePopup(false)}
          secondOptionHandler={saveAsDraft}
          firstOptionText='Edit Date'
          secondOptionText='Save as draft'
        />
      )}

      {publishConfirmationPopupOnClose && (
        <Confirmation
          heading='This spotlight will only display for users who have not seen it.'
          subHeading='If you want all users to see this spotlight, please make a clone instead'
          firstOptionHandler={saveAsPublishedOnClose}
          secondOptionHandler={() => setPublishConfirmationPopupOnClose(false)}
          firstOptionText='Publish'
          secondOptionText='Cancel'
        />
      )}
      {state.loading ? (
        <div className={styles.loadingContainer}>
          <PropagateLoader loading={true} size={10} color='#3576E8' />
        </div>
      ) : (
        <>
          <div className={styles.content} ref={containerRef} id='spotlight-create-modal'>
            <Accordion>
              <AccordionItem title='Create' expanded onToggle={handleAccordionToggle}>
                <Create
                  state={{...state, track: props.track}}
                  dispatch={dispatch}
                  containerRef={containerRef}
                  me={props.me}
                  maxHeadlineLength={maxHeadlineLength}
                  maxMessageLength={maxMessageLength}
                  maxPreviewDescriptionHeight={maxPreviewDescriptionHeight}
                />
              </AccordionItem>
              <AccordionItem
                title='Publish & Share'
                expanded
                onToggle={handleAccordionToggle}
              >
                <PublishSettings
                  state={state}
                  dispatch={dispatch}
                  containerRef={containerRef}
                  container={Spotlight}
                  id={state.step!}
                />
              </AccordionItem>
              {state.published_on && !state.edit_mode && (
                <AccordionItem title='Analyze' onToggle={handleAccordionToggle}>
                  <Analyze state={state} />
                </AccordionItem>
              )}
            </Accordion>
          </div>
        </>
      )}
    </>
  );
};

export default SpotlightModal;
