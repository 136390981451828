import {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
const TopicsPage = lazy(() => import('./pages/TopicsPage'));
const Wiki = lazy(() => import('../../wiki'));
const PrintToPDF = lazy(() => import('./pages/PrintToPdf'));
const OnboardingTopics = lazy(() => import('../../OnboardingTopics'));
const TopicPermissionDeniedPage = lazy(() => import('./pages/TopicPermissionDeniedPage'));
const SpekPermissionDeniedPage = lazy(() => import('./pages/SpekPermissionDeniedPage'));
const SpekDeletedPage = lazy(() => import('./pages/SpekDeletedPage'));
const FlowPermissionDeniedPage = lazy(() => import('../Flows/pages/PermissionDenied'));
const ReorderSpeks = lazy(() => import('./pages/ReorderContent'));
const WikiRoute = () => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/wiki/print/:ids?' exact>
          <PrintToPDF />
        </Route>
        <Route path='/app/wiki/topics/:create?' exact>
          <TopicsPage />
        </Route>
        <Route path='/app/wiki/onboarding' exact>
          <OnboardingTopics />
        </Route>
        <Route path='/app/wiki/topic-permission-denied' exact>
          <TopicPermissionDeniedPage />
        </Route>
        <Route path='/app/wiki/spek-permission-denied' exact>
          <SpekPermissionDeniedPage />
        </Route>
        <Route path='/app/wiki/flow-permission-denied' exact>
          <FlowPermissionDeniedPage />
        </Route>
        <Route path='/app/wiki/spek-deleted' exact>
          <SpekDeletedPage />
        </Route>
        <Route path='/app/wiki/reorder-speks/:topicId' exact>
          <ReorderSpeks />
        </Route>
        <Route path='/app/wiki/:scopeType?/:scopeId?/:filters?' exact>
          <Wiki />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default WikiRoute;
