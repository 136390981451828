import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {IOptionType, ITopic} from 'spekit-types';
import {Divider, DSModal as Modal, ModalBody} from 'spekit-ui';
import {closeTopicModal} from '../../../../redux/actions';
import {RootState} from '../../../../reduxStore';
import {Details} from './Details';
import {Header} from './Header';
import {getSchema} from './validations';
import {useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {RelatedDomains} from './RelatedDomains';
import {Audience} from './Audience';

export interface ITopicModalState {
  name: string;
  description: string;
  icon: {
    image: ITopic['icon'] | string | null;
    fontawesome: string | null;
  };
  topics: IOptionType[];
  domains: {
    name: string;
    includeSubdomain: boolean;
    includePaths: boolean;
    paths: {
      value: string;
    }[];
  }[];
  teams: {
    value: string;
    label: string;
    isFixed: boolean;
    accessAllTopics: boolean;
  }[];
  allTeams: boolean;
}

const initialValues: ITopicModalState = {
  name: '',
  description: '',
  icon: {
    image: null,
    fontawesome: null,
  },
  topics: [],
  domains: [
    {
      name: '',
      includeSubdomain: false,
      includePaths: false,
      paths: [{value: ''}],
    },
  ],
  teams: [],
  allTeams: false,
};

export const TopicModal = () => {
  const {toggle: isOpen, term: topic} = useSelector(
    (state: RootState) => state.topicModal
  );

  const methods = useForm<ITopicModalState>({
    defaultValues: initialValues,
    resolver: yupResolver(getSchema()),
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (topic && isOpen) {
      methods.reset({
        name: topic.name || '',
        description: topic.description || '',
        icon: {
          image: topic.icon || null,
          fontawesome: topic.font_awesome_id || null,
        },
        topics: topic.parents?.map((parent) => ({
          label: parent.name,
          value: parent.id,
        })),
        domains:
          topic.domains.length > 0
            ? topic.domains.map((domain) => ({
                name: domain.name,
                includeSubdomain: domain.include_subdomain,
                includePaths: domain.paths.length > 0,
                paths:
                  domain.paths.length > 0
                    ? domain.paths.map((path) => ({value: path}))
                    : [{value: ''}],
              }))
            : initialValues.domains,
        teams: topic.teams.map((team) => ({
          value: team.id,
          label: team.name,
          isFixed: !team.can_user_manage,
          accessAllTopics: team.access_all_topics,
        })),
        allTeams: topic.all_teams,
      });
    }
  }, [topic, methods, isOpen]);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    methods.reset(initialValues);
    if (location.pathname.includes('/create')) {
      history.push(location.pathname.replace('/create', ''));
    }

    dispatch(closeTopicModal());
  }, [location.pathname, history, dispatch, methods]);

  if (!isOpen) return null;

  return (
    <Modal
      size='lg'
      scrollBehavior='inside'
      onClose={handleClose}
      isOpen={isOpen}
      closeOnEsc={false}
      autoFocus={false}
      trapFocus={false}
      blockScrollOnMount={false}
    >
      <FormProvider {...methods}>
        <Header topic={topic} handleClose={handleClose} />

        <ModalBody mt={32} pl={4} pr={4}>
          <Details topic={topic} />
          <Divider orientation='horizontal' my={32} />
          <RelatedDomains />
          <Divider orientation='horizontal' my={32} />
          <Audience handleClose={handleClose} />
        </ModalBody>
      </FormProvider>
    </Modal>
  );
};
