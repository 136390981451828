import React, {useState} from 'react';
import {IDrive} from 'spekit-types';
import {
  ActionWithConfirmation,
  ModalBody,
  ModalFooter,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  DSButton as Button,
} from 'spekit-ui';
import {Connection} from '@integration-app/react';
import {TIntegrationKeys} from '../constants';
import {TabPanel} from '@chakra-ui/tabs';
import {DriveSelector} from './DriveSelector';
import {NoConnection} from './NoConnection';
import {useDrives} from '../hooks/useDrives';

interface IProps {
  selectedDrive?: IDrive;
  onDriveSelect: (store: TIntegrationKeys, drive: IDrive) => void;
  onStoreClick: (store: TIntegrationKeys) => void;
  handleConnect: (store: TIntegrationKeys) => void;
  hasContentIntegrationSharepointFlag?: boolean;
  hasContentIntegrationConfluenceFlag?: boolean;
  setActiveTab: (activeTab: number) => void;
  activeTab: number;
  connections: Record<TIntegrationKeys, Connection>;
  selectedStore: TIntegrationKeys;
  handleDisconnect: () => void;
  isDisconnecting: boolean;
  storeName: string;
}

export const ContentStores = (props: IProps) => {
  const {
    selectedDrive,
    onDriveSelect,
    onStoreClick,
    handleConnect,
    hasContentIntegrationSharepointFlag,
    hasContentIntegrationConfluenceFlag,
    setActiveTab,
    activeTab,
    connections,
    selectedStore,
    handleDisconnect,
    isDisconnecting,
    storeName,
  } = props;

  const contentStoreDrives = useDrives(
    hasContentIntegrationSharepointFlag,
    hasContentIntegrationConfluenceFlag
  );

  return (
    <>
      <ModalBody my={24}>
        <Tabs
          data-testid='content-stores'
          onChange={(index) => {
            onStoreClick(contentStoreDrives[index].store.connectionKey);
            setActiveTab(index);
          }}
          index={activeTab}
        >
          <TabList>
            {contentStoreDrives.map(({store}) => (
              <Tab data-testid={`${store.connectionKey}-tab`} key={store.connectionKey}>
                {store.label}
              </Tab>
            ))}
          </TabList>

          <TabPanels p={0}>
            {contentStoreDrives.map(({drives, store, connection}) => (
              <TabPanel key={store.connectionKey}>
                {connection && !connection.disconnected ? (
                  <DriveSelector
                    store={store}
                    selectedDrive={selectedDrive}
                    isLoading={drives.isLoading}
                    drives={drives.data || []}
                    onClick={(drive) => onDriveSelect(store.connectionKey, drive)}
                    activeTab={activeTab}
                  />
                ) : (
                  ((drives.fetchStatus !== 'fetching' && !drives.isLoading) ||
                    (connection as unknown as boolean) === false) && (
                    <NoConnection
                      name={store.label}
                      handleConnect={handleConnect}
                      storeKey={store.connectionKey}
                    />
                  )
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </ModalBody>
      <ModalFooter p={0} m={0} justifyContent={'space-between'}>
        {connections[selectedStore] && !connections[selectedStore].disconnected && (
          <ActionWithConfirmation
            confirmationHeader={`Disconnect ${storeName}?`}
            confirmationMessage={`Are you sure? You will no longer be able to sync ${storeName} files into Spekit.`}
            confirmActionText='Yes, disconnect'
            confirmAction={handleDisconnect}
            placement='top-start'
            customButton={
              <Button
                data-testid='confirmation-popover-btn'
                variant='danger'
                size='medium'
                isLoading={isDisconnecting}
              >
                Disconnect
              </Button>
            }
          />
        )}
      </ModalFooter>
    </>
  );
};
