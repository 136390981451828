import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {Box, EncapsulatedInput, Flex, Text, DSTooltip as Tooltip, Icon} from 'spekit-ui';
import type {ITopicModalState} from './TopicModal';
import {RiInformationLine} from 'react-icons/ri';
import {TopicUpload} from 'spekit-shared-components';
import {TopicPicker} from 'spekit-shared-components/src/speks/TopicPicker';
import {ITopic} from 'spekit-types';

interface IDetailsProps {
  topic: ITopic | null;
}

export const Details = (props: IDetailsProps) => {
  const {control, setValue} = useFormContext<ITopicModalState>();
  const {topic} = props;
  const icon = useWatch({control, name: 'icon'});

  const getIcon = (icon: {image: string | null; fontawesome: string | null}) => {
    setValue('icon', icon);
  };

  return (
    <Flex flexDirection='column' gap={16}>
      <Controller
        name='name'
        control={control}
        render={({field, fieldState}) => (
          <EncapsulatedInput
            {...field}
            isRequired
            label='Topic name'
            testId='topic-name'
            tooltip='Special characters like ampersand (&), question mark (?) and comma (,) are not supported.'
            placeholder='Enter Topic name'
            isInvalid={!!fieldState.error}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Flex gap={42} alignItems='baseline'>
        <Box width='50%'>
          <Controller
            name='description'
            control={control}
            render={({field, fieldState}) => (
              <EncapsulatedInput
                {...field}
                label='Description'
                testId='topic-description'
                placeholder='Enter a description'
                isInvalid={!!fieldState.error}
                errorMessage={fieldState.error?.message}
                multiline
                textareaProps={{
                  rows: 5,
                }}
              />
            )}
          />
        </Box>
        <Flex flexDirection='column' gap={6}>
          <Flex align='center' gap={4}>
            <Text variant='body2'>Icon</Text>
            <Tooltip
              label='An icon size of 132 x 132px is recommended when uploading a custom icon.'
              placement='bottom'
              shouldWrapChildren
            >
              <Box display='flex' alignItems='center'>
                <Icon as={RiInformationLine} height='16px' width='16px' />
              </Box>
            </Tooltip>
          </Flex>
          <TopicUpload
            getIcon={getIcon}
            image={icon.image}
            fontawesome={icon.fontawesome}
          />
        </Flex>
      </Flex>
      <Box width='50%'>
        <TopicPicker
          type='topic'
          label='Parent Topics'
          placeholder='Select parent Topics'
          tooltip='Assigning a Parent Topic creates a Parent-Child relationship. This Topic will become a Sub-Topic and will only be visible within its Parent Topic. It will not appear on the Topics page.'
          topicToIgnore={topic ? {label: topic.name, value: topic.id} : undefined}
        />
      </Box>
    </Flex>
  );
};
