import React from 'react';
import {
  DSButton as Button,
  Menu,
  MenuList,
  MenuButton,
  ComplexMenuItem,
  Tag,
  Icon,
  Graphic,
} from 'spekit-ui';

import {RiAddLine} from 'react-icons/ri';
import {IGraphicProps} from 'spekit-ui/src/design-system/Graphic/Graphic';
import {IFlagState, TMenuType, TRole} from 'spekit-types';
import getMenuOptions from './menuOptions';

interface ICreateMenuProps {
  onCreate: (selection: TMenuType) => void;
  canCreate: boolean;
  flags: Partial<IFlagState>;
  role: TRole;
  size: 'small' | 'medium';
  variant?: 'contained' | 'icon';
}

export const CreateMenu = (props: ICreateMenuProps) => {
  const {
    onCreate,
    canCreate,
    role,
    flags,
    size = 'medium',
    variant = 'contained',
  } = props;

  const menuPermissions = {
    file: true,
    'content-integration': flags?.hasContentIntegrationFlag && role !== 'Viewer',
    'knowledge-check': flags?.hasQuizFlag,
    spotlight: flags?.hasSpotlightFlag,
    flow: flags?.hasFlowFlag,
  };

  const menuOptions = getMenuOptions(menuPermissions, flags);

  if (!canCreate) return null;

  return (
    <Menu>
      <MenuButton
        as={Button}
        size={size}
        variant={variant}
        aria-label='Create'
        colorScheme='primary'
        leftIcon={
          variant === 'contained' ? (
            <Icon height='16px' width='16px' as={RiAddLine} />
          ) : undefined
        }
        icon={
          variant === 'icon' ? (
            <Icon height='16px' width='16px' as={RiAddLine} />
          ) : undefined
        }
        data-testid='create-menu-btn'
        tooltipLabel={variant === 'icon' ? 'New' : undefined}
      >
        {variant === 'contained' ? 'New' : null}
      </MenuButton>

      <MenuList zIndex={2} data-testid='create-menu-container'>
        {menuOptions
          .filter((option) => option.permission)
          .map((option) => {
            const {description, contentType, text, id, isTaggable} = option;
            return (
              <ComplexMenuItem
                data-testid={id}
                mb={10}
                description={description}
                text={text}
                tag={
                  isTaggable && (
                    <Tag colorScheme='primary' variant='status' fontWeight='semibold'>
                      NEW
                    </Tag>
                  )
                }
                key={id}
                onClick={() => onCreate(id)}
                icon={<Graphic size='sm' contentType={contentType} />}
              />
            );
          })}
      </MenuList>
    </Menu>
  );
};
