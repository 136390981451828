import {useCallback, useEffect, useState} from 'react';
import {PropagateLoader} from 'react-spinners';
import {logging, termView, shareLinks} from 'spekit-datalayer';
import {track} from '../utils/analytics';
import {IPublicContent} from 'spekit-types';
import {
  DSButton as Button,
  Flex,
  Text,
  Icon,
  Box,
  useToast,
  DSTooltip as Tooltip,
  Heading,
  Divider,
  Link,
  PDFViewer,
} from 'spekit-ui';
import ErrorView from './ErrorView';
import {useParams} from 'react-router-dom';
import {RiDownloadLine, RiLink} from 'react-icons/ri';
import {colors} from 'spekit-ui/src/design-system/theme';
import ContentTimeTracker from '../ContentTimeTracker/ContentTimeTracker';
import {EmailAddressModal} from './EmailAddressModal';
import {setItem, getItem} from 'spekit-ui/src/utils/localStorage';
import {ReadOnlyView} from 'spekit-shared-components/src/RichTextEditor/components';

export const PublicContent = () => {
  const emailAddressStorageKey = 'smart_send_user_email';

  const [content, setContent] = useState<IPublicContent>();
  const [error, setError] = useState(false);
  const [termTimeViewedAt, setTermTimeViewedAt] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const {contentType, contentId} = useParams<{contentType: string; contentId: string}>();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(true);
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [isTimeTrackingEnabled, setIsTimeTrackingEnabled] = useState(false);
  const toast = useToast();

  const logTermView = useCallback(() => {
    if (content) {
      let dataPayload = {
        term_id: content.id,
        term_type: content.type,
        term_label: content.label,
        source: 'Webapp',
        time_spent: termView.getTotalTimeSpent(termTimeViewedAt),
        screen_name: 'Public Spek View',
      };
      track('Spek Viewed', dataPayload);
    }
  }, [content, termTimeViewedAt]);

  const handleEmailAndTrackingInitialVisibility = (response: IPublicContent) => {
    const emailAddress = getItem(emailAddressStorageKey);
    if (!response.is_email_required || response.is_internal_user || !!emailAddress) {
      setIsEmailModalOpen(false);

      if (!!emailAddress) {
        setUserEmailAddress(emailAddress);
      }

      setIsTimeTrackingEnabled(!response.is_internal_user);
    }
  };

  useEffect(() => {
    if (!content && !error) {
      (async () => {
        if (contentType && contentId) {
          try {
            const response = await shareLinks.getPublicContent(contentType, contentId);
            if (response.success !== false) {
              setContent(response);
              handleEmailAndTrackingInitialVisibility(response);
            } else {
              setIsPrivate(response.private);
              setError(true);
            }
          } catch (e) {
            setError(true);
            logging.capture(e);
          }
        }
        setTermTimeViewedAt(new Date().getTime());
      })();
    }

    return () => logTermView();
  }, [logTermView, error, content, contentType, contentId]);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast({
        variant: 'success',
        description: 'Link copied to clipboard',
      });
    } catch (e) {
      toast({
        variant: 'error',
        description: `Couldn't copy to clipboard`,
      });
    }
  };

  const handleAssetDownload = () => {
    if (content) {
      // Track download event
      const trackingPayload = {
        asset_id: content.id,
        asset_label: content.label,
        content_type: content.type,
        source: 'Webapp',
        screen_name: 'Public Spek View',
      };
      track('SmartSend Asset Downloaded', trackingPayload);

      // Open the download URL in a new tab for download
      window.open(content.download_url, '_blank');
    }
  };

  if (!content && !error) {
    return (
      <Flex
        h='100vh'
        alignItems='center'
        justifyContent='center'
        data-testid='public-content-loader'
      >
        <PropagateLoader loading={true} size={10} color={colors.primary[500]} />
      </Flex>
    );
  }

  const handleOnContinueEmailModal = (emailAddress: string) => {
    setItem(emailAddressStorageKey, emailAddress);
    setUserEmailAddress(emailAddress);
    setIsEmailModalOpen(false);
    setIsTimeTrackingEnabled(true);
  };

  const renderSpek = () => {
    if (!!content?.definition) {
      if (isTimeTrackingEnabled) {
        return <ContentTimeTracker content={content} email={userEmailAddress} />;
      } else {
        return <ReadOnlyView value={content.definition} />;
      }
    }

    return <Text variant='body2'>Spek is not defined.</Text>;
  };
  const renderFile = () => {
    if (content) {
      return isTimeTrackingEnabled ? (
        <ContentTimeTracker content={content} email={userEmailAddress} />
      ) : (
        <PDFViewer url={content.url || content.definition!} />
      );
    }
    return null;
  };

  return content ? (
    <Flex direction='column' h='100vh' bgColor='neutral.200'>
      <Flex
        p={14}
        m={12}
        borderRadius={6}
        alignItems='center'
        bgColor='neutral.0'
        justifyContent='space-between'
        flexWrap={'wrap'}
      >
        <Flex alignItems='center' overflow='hidden' flexGrow={1}>
          <Tooltip label={content.shareable_bundle_name}>
            <Text
              maxW='80%' // reduce it to 50% when the following code is uncommented
              overflow='hidden'
              whiteSpace='nowrap'
              fontWeight='semibold'
              textOverflow='ellipsis'
              data-testid='shared-file-name'
            >
              {content.shareable_bundle_name}
            </Text>
          </Tooltip>

          {contentType === 'content' && !content.is_default && (
            <>
              <Divider
                orientation='vertical'
                m={0}
                mx={16}
                h='auto'
                alignSelf='stretch'
              />
              <Text variant='body2' data-testid='sent-from-email'>
                Sent from{' '}
                <Link href={`mailto:${content.sent_from}`}>{content.sent_from}</Link>
              </Text>
            </>
          )}
        </Flex>
        <Flex gap={12}>
          {(contentType === 'asset' || content.type === 'asset') &&
            content.download_url &&
            content.allow_download && (
              <Button
                leftIcon={<Icon as={RiDownloadLine} h={4} w={4} />}
                size='medium'
                variant='outlined'
                colorScheme='primary'
                onClick={handleAssetDownload}
                mr={2}
                data-testid='download-asset'
              >
                Download
              </Button>
            )}
          <Button
            size='medium'
            variant='contained'
            colorScheme='primary'
            onClick={handleClick}
            leftIcon={<Icon as={RiLink} h={4} w={4} />}
            data-testid='copy-share-link'
          >
            Copy share link
          </Button>
        </Flex>
      </Flex>

      <Flex
        flexGrow={1}
        bgColor='neutral.25'
        alignItems='stretch'
        justifyContent='center'
      >
        {contentType === 'asset' || content.type === 'asset' ? (
          renderFile()
        ) : (
          <Box
            p={40}
            my={22}
            w='100%'
            mx={12}
            maxW='816px'
            bgColor='neutral.0'
            border='1px solid'
            borderColor='neutral.300'
          >
            <Box>
              <Heading as='h4' fontWeight='semibold' mb={24}>
                {content.label}
              </Heading>
              {renderSpek()}
            </Box>
          </Box>
        )}
        <EmailAddressModal
          isOpen={isEmailModalOpen}
          onViewContent={handleOnContinueEmailModal}
          onClose={() => {
            setIsEmailModalOpen(false);
          }}
        ></EmailAddressModal>
      </Flex>
    </Flex>
  ) : (
    <ErrorView isPrivate={isPrivate} />
  );
};
