import React from 'react';
import styles from './create.module.css';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Multimedia,
  ContentPreview,
  Box,
  Text,
} from 'spekit-ui';
import ViewWalkthrough from '../viewWalkthrough/viewWalkthrough';
import Engagements from './engagements/engagements';
import {Audience} from '../commons';
import {WalkthroughDetail} from 'spekit-datalayer/src/spotlights/spotlight.types';
import {Dispatch, State} from './types';
import {Spotlight} from 'spekit-datalayer';
import {RichTextEditor} from '../RichTextEditor/RichTextEditor';
import {spotLightEditorConfig} from '../RichTextEditor/config/editorConfig';
import Headline from './spotlights/headline';
import EngagementDestination from './EngagementDestination';
import SpotlightView from './SpotlightView/SpotlightView';

interface Props {
  state: State;
  dispatch: Dispatch;
  containerRef: any;
  me: any;
  maxHeadlineLength: number;
  maxMessageLength: number;
  maxPreviewDescriptionHeight: number;
}

const Create: React.FC<Props> = ({
  state,
  dispatch,
  containerRef,
  me,
  maxHeadlineLength,
  maxMessageLength,
  maxPreviewDescriptionHeight,
}) => {
  const getPreviewSpotlight = (state: any): WalkthroughDetail => {
    return {
      id: 'zzz',
      created_by: {
        first_name: me?.first_name,
        last_name: me?.last_name,
        id: '',
        profile_image: me?.user_profile?.profile_image,
      },
      label: '',
      status: 'unpublished',
      published_on: '',
      published_by: {first_name: '', last_name: '', id: ''},

      steps: [
        {
          id: '',
          created_on: '',
          created_by: {
            id: '123',
            first_name: 'Jon',
            last_name: 'Doe',
          },
          label: state.headline,
          description: state.message,
          system_image_id: state.system_image,
          image: state.image,
          embed_url: state.embed,
          engagements: [
            {
              id: '',
              created_on: '',
              created_by: {
                id: '',
                first_name: '',
                last_name: '',
              },
              updated_on: null,
              updated_by: {
                first_name: '',
                last_name: '',
                id: '',
              },
              content: {
                id: '',
              },
              label: state.engagements?.label || 'Got it',
              type: '',
            },
          ],
        },
      ],
    };
  };

  const handleDeletePreview = () => {
    dispatch({type: 'DELETE_PREVIEW'});
  };

  const handleEmbedVideo = (videoHref: string) => {
    dispatch({type: 'SET_EMBED', payload: videoHref});
  };

  const handleImage = (image: string) => {
    dispatch({type: 'SET_IMAGE', payload: image});
  };

  const handleImageLoading = (isLoading: boolean) => {
    dispatch({type: 'SET_IMAGE_LOADING', payload: isLoading});
  };

  const handleSystemImage = (payload: string) => {
    dispatch({type: 'SET_SYSTEM_IMAGE', payload: payload});
  };
  const onChangeTeam = (teams: any) => {
    let engagments = {...state.engagements};
    dispatch({type: 'SET_TEAMS', payload: teams});
    if (!['acknowledge-and-close', 'url'].includes(engagments.type)) {
      engagments.content = {};
    }

    if (state.edit_mode && state.id) {
      Spotlight.updateTeams(teams, state.id);
    }

    dispatch({type: 'SET_ENGAGEMENTS', payload: engagments});
  };

  // @ts-ignore
  return (
    <div className={styles.container}>
      <Box pt={24} px={32} pb={64}>
        <Text fontWeight='bold' fontSize='medium' color='neutral.700'>
          Content
        </Text>
        <div>
          <div className={styles.messageContent}>
            <Headline
              state={state}
              dispatch={dispatch}
              maxHeadlineLength={maxHeadlineLength}
            />
            <FormControl
              isRequired
              isInvalid={
                state.messageError ||
                state.messageCharactersCount >= maxMessageLength ||
                state.previewDescriptionHeight > maxPreviewDescriptionHeight
              }
            >
              <FormLabel mt={12}>Message</FormLabel>

              <Box minH='160px' className='spotlight-editor'>
                <RichTextEditor
                  placeholder='Enter a message'
                  value={state.message}
                  onChange={(value) => dispatch({type: 'SET_MESSAGE', payload: value})}
                  onCharactersCountChange={(value) =>
                    dispatch({type: 'SET_MESSAGE_CHARACTERS_COUNT', payload: value})
                  }
                  setUploadBusy={() => {}}
                  unSetUploadBusy={() => {}}
                  config={spotLightEditorConfig}
                  editingViewReadOnly={state.read_mode}
                />
              </Box>

              <FormErrorMessage data-testid='description-error-message'>
                {state.messageCharactersCount >= maxMessageLength && (
                  <>
                    Message must be less than 280 characters.
                    <br />
                  </>
                )}
                {state.previewDescriptionHeight > maxPreviewDescriptionHeight &&
                  'You have reached the maximum height for Spotlight messages.'}
                {state.messageError &&
                  !(state.messageCharactersCount >= maxMessageLength) &&
                  !(state.previewDescriptionHeight > maxPreviewDescriptionHeight) &&
                  'Enter a message.'}
              </FormErrorMessage>
            </FormControl>
          </div>

          <Box mt={12}>
            <Multimedia
              readOnly={state.read_mode}
              image={state.image}
              imageLoading={state.image_loading}
              embed={state.embed}
              systemImage={state.system_image}
              host={state.host}
              editMode={state.edit_mode}
              handleDeletePreview={handleDeletePreview}
              handleEmbedVideo={handleEmbedVideo}
              handleImage={handleImage}
              handleImageLoading={handleImageLoading}
              handleSystemImage={handleSystemImage}
              track={state.track}
              notify={state.notify}
              screenName='Spotlight Modal'
              label='Multimedia'
            />
          </Box>
        </div>
        <div className={styles.hr} />
        <Audience
          dispatch={dispatch}
          isSpotlight={true}
          containerRef={containerRef}
          state={state}
          onChange={onChangeTeam}
        />
        <Text mt={16} fontSize='medium' fontWeight='bold'>
          Button
        </Text>

        <Engagements state={state} dispatch={dispatch} containerRef={containerRef} />
      </Box>

      <SpotlightView
        heading='Preview'
        subHeading='This is what your team sees'
        step={getPreviewSpotlight(state).steps[0]}
        creator={getPreviewSpotlight(state).created_by}
        host={state.host}
        isHTML
        isHTMLHeader
        onDescriptionHeightChange={(height) =>
          dispatch({type: 'SET_PREVIEW_DESCRIPTION_HEIGHT', payload: height})
        }
      />
    </div>
  );
};

export default Create;
